.store-locator {
  .location__name {
    @include solid-breakpoint("md") {
      font-size: $text-2;
      line-height: $text-2;
    }
  }

  .button--change-store {
    align-self: flex-start;
    white-space: nowrap;
    
    @include solid-breakpoint("md") {
      margin-left: $space-2;
    }
  }
}
.find-store-button {
  @include solid-breakpoint("md") {
    min-width: 108px;
  }
}
