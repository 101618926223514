$generate-responsive-classes: true;

html {
  overflow-x: hidden;
}

body,
html {
  min-height: 100vh;
  background: $fill-white;
}

.content-wrap {
  max-width: $content-width;
}

.link-tasty {
  color: $color-primary-action;
  transition: color .15s ease;
  svg {
    fill: $color-primary-action;
    transition: fill .15s ease;
  }

  &:hover {
    color: $color-primary-action-hover;
    transition: color .15s ease;

    svg {
      fill: $color-primary-action-hover;
      transition: fill .15s ease;
    }
  }
}

.link-tasty--alternate {
  color: $color-primary-action-alternate;
  transition: color .15s ease;
  &:hover {
    color: $color-primary-action-alternate-hover;
  }
}

@media (min-width: $solid-md) {
  .compilation-name,
  .recipe-name {
    font-size: 36px;
    line-height: 1;
  }
}

@media (min-width: $solid-lg) {
  .compilation-name,
  .recipe-name {
    font-size: 56px;
    line-height: 1;
  }
}
