.video-wrap,
.img-wrap {
  top: 82px;
}

.non-video-recipe-ad-sidebar {
  flex-shrink: 0;
  max-width: 348px;
}

.non-video {
  picture {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  img {
    width: 100%;
  }
}

.ingredients-prep {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include solid-breakpoint("md") {
    flex-direction: row;
    min-width: 58.33333%;
    padding-right: 1rem;
    &:not(.ingredients-prep--vertical) {
      width: 58.33333%;
      max-width: 80%;
    }
  }
}
.two-columns-recipe {
  .recipe-content {
    @include solid-breakpoint("md") {
      flex-direction: row;
      min-width: 60.667%;
      padding-right: 0.25rem;
      &:not(.ingredients-prep--vertical) {
        width: 60.667%;
        max-width: 80%;
      }
    }
  }

  .recipe-content + .video--recipe,
  .recipe-content + .compilation-child-recipe-image {
    display: none;
    @include solid-breakpoint("md") {
      display: block;
    }
  }

  .ingredients-prep {
    width: auto;
    max-width: 100%;
    padding-right: 0;
  }

  .video--recipe {
    @include solid-breakpoint("md") {
      margin: 0;
      &:not(.video--vertical) {
        padding-left: 1.25rem;
        //width: 41.66667%;
      }
    }
  }
  .community-recipes-container {
    @include solid-breakpoint("md") {
      .button--tasty {
        display: none;
      }
    }
  }
}

.non-video-recipe {
  .ingredients-prep {
    @include solid-breakpoint("md") {
      width: 100%;
    }
  }
}

.ugc-shoppable-recipe-space {
  margin-left: $space-2;
}
