.change-store-modal__postcode-form {
  border: 2px solid $color-tasty-pink-5;
  border-radius: 100px;

  &:focus-within {
    border-color: $color-tasty-pink-8;
  }

  input {
    @include input-reset;
    width: 100%;
  }

  svg {
    fill: $color-tasty-pink-5;
  }

  .change-store-modal__input-clear-button:hover {
    fill: $color-tasty-pink-8;
  }
  
  button {
    @include button-reset;
  }
}

.change-store-modal__store-list-wrapper {
  padding: 0;
  margin: 0 -36px;

  .change-store-modal__store-list-item {
    cursor: pointer;
    list-style: none;
    padding: $space-2 36px;
    border-top: 2px $color-gray-1 solid;

    &:hover {
      background-color: $color-gray-1;
    }
  }
}

.change-store-modal__loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20rem;

  img {
    width: 104px;
    height: 104px;
  }
}

.change-store-modal__invalid-postcode {
  margin: 1rem;
  color: $color-error;
}

.change-store-modal__no-results {
  margin-top: 5rem;
}
