.video-content {
  background: $color-gray-background;
}
.video-thumbnail {
  background: $color-gray-background;
  height: 100%;
  img {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.video--vertical {
  .video-sizer {
    display: block;
    height: 100%;
    width: auto;
    position: absolute;
  }
  display: flex;
  justify-content: center;
  width: 100%;
  .video-wrap--vertical {
    height: calc(100vh - 12px);

    @media (max-aspect-ratio: 10/16) {
      height: auto;
      width: 100%;
      max-width: 100%;
      aspect-ratio: 9/16;
    }
  }
  .video-content--vertical {
    display: inline-block;
    height: 100%;
    width: auto;
    aspect-ratio: 9 / 16;

    @media (max-aspect-ratio: 9/16) {
      max-width: 100%;
      width: 100%;
    }
  }
  @include solid-breakpoint("md") {
    .video-wrap--vertical {
      height: calc(100vh - 88px);
      min-height: 550px;
    }
    @media (max-aspect-ratio: 5/4) {
      .video-wrap--vertical {
        height: auto;
      }
      .video-content--vertical {
        width: 100%;
      }
      .video-sizer {
        display: none;
      }
    }
    @media (min-aspect-ratio: 3/2) {
      flex-shrink: 3;
    }
    display: block;
  }
}

.video--compilation,
.video--recipe {
  margin-bottom: $space-05;

  @include solid-breakpoint("sm") {
    &:not(.video--vertical) {
     margin: 0 $space-2 $space-2 $space-2;
    }
  }
  @include solid-breakpoint("md") {
    margin: 0 $space-2 $space-4 0;
    &:not(.video--vertical) {
      padding-left: $space-2;
      width: 41.66667%;
    }
  }
  .video-wrap {
    @supports not (-ms-high-contrast: none) {
      position: -webkit-sticky;
      position: sticky;
    }
    @include solid-breakpoint("md") {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: flex-end
    }
  }
  .video-content {
    overflow: hidden;
  }
  .video-content:not(.video-content--vertical) {
    width: 100%;
  }
}

.has-tb-ads .video--vertical {
  @include solid-breakpoint("md") {
    .video-wrap--vertical {
      height: calc(100vh - 144px);
    }
  }
}