.show-more {
  display: flex;
  margin: 0 $space-2;
  padding-bottom: $space-2;
}

.more-guides__show-more-feed {
  margin-top: -1.5rem;
}

.more-guides {
  .feed-item {
    min-width: 0;
    width: calc(50% - 1rem);
    @include solid-breakpoint('lg') {
      width: calc(25% - 1rem);
    }
  };
}
