//
// Importing just what is needed from solid
// --------------------------------------
@import "solid-helpers"; // mixins and variables
@import "solid-base"; // normalize and resets
@import "solid-components/pagination";

@import "base/variables"; // variables needs to be imported first
@import "base/base";
@import "base/fonts";
@import "base/mixins";
@import "base/utilities";
@import "base/buttons";

//
// Common components
// --------------------------------------
@import "components/modals";
@import "components/subbuzzes";

// target the first div immediately inside body added by nextJS programmatically
// https://github.com/zeit/next.js/issues/4834#issuecomment-430610292
// >  "We're not planning to change #__next anytime soon, as React always needs a root, so you can select this safely.
//    There's no need to add an extra className as that makes the initial HTML document larger."
#__next {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  min-height: 100%;
}

#modal {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999;

  .page-modal {
    width: 100vw;
    height: 100vh;
  }
}

// Needs to be after above, so those in print file will take precedence when applicable
@import "base/print";

// these can apply across different types of pages; Padding at top adjust for IE10/11
.content-container {
  padding-top: 1rem;
  flex: 1 0 auto;
}

@media (min-width: $solid-md) {
  .content-container {
    padding-top: $space-4;
  }
  .content-container.recipe-page {
    padding-top: $space-1;
  }
}

@mixin ie-content-container {
  // default case - handles small breakpoints that have no awareness ads
  .content-container {
    margin-top: 0px !important;
    padding-top: 85px;
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  @include ie-content-container;
  iframe[name="__cmpLocator"] {
    height: 0;
  }
}

.recipe-content {
  min-height: 450px;
  flex-direction: column;
}

@include solid-breakpoint("md") {
  .recipe-content {
    min-height: 350px;
    flex-direction: row;
  }
}
@include solid-breakpoint("lg") {
  .recipe-content {
    min-height: 400px;
  }
}

.position-sticky {
  @supports not (-ms-high-contrast: none) {
    position: -webkit-sticky;
    position: sticky;
  }
}

.loading-donut {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.js-hidden {
  display: none;
}

.recirc-ad-sidebar {
  @include solid-breakpoint("md") {
    width: 348px;
    flex-shrink: 0;
  }
}
.sticky-wrapper__sidebar {
  @extend .position-sticky;
  top: 75px;
  padding-bottom: 1.5rem;
}

.screenreader-only {
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
}

.disclaimer-link {
  color: $color-primary-action-disclaimer;
  svg {
    fill: $color-primary-action-disclaimer;
  }
  &:hover {
    color: $color-primary-action-hover;
    transition: color 0.15s ease 0s;

    svg {
      fill: $color-primary-action-hover;
      transition: fill 0.15s ease 0s;
    }
  }
}

.image-1-1, .video-1-1 {
  @include aspect-ratio(1, 1);
}
.image-4-5, .video-4-5 {
  @include aspect-ratio(4 ,5);
}
.image-9-16, .video-9-16 {
  @include aspect-ratio(9, 16);
}
.image-40-71, .video-40-71 {
  @include aspect-ratio(40, 71);
}
.image-16-9, .video-16-9 {
  @include aspect-ratio(16, 9);
}

/*
 * Import styles from components and pages
 *   Eventually these files should be converted into css module, but as
 *   first step in NextJs migration, will import them all here
 */
@import '../components/AppBadge/AppBadge.scss';
@import '../components/ads/Ad/Ad.scss';
@import '../components/ads/AdAdapted/AdAdapted.scss';
@import '../components/article/ArticleHeader/ArticleHeader.scss';
@import '../components/article/CommerceDisclaimer/CommerceDisclaimer.scss';
@import '../components/article/LatestGuides/LatestGuides.scss';
@import '../components/article/MoreGuides/MoreGuides.scss';
@import '../components/article/SubbuzzShares/SubbuzzShares.scss';
@import '../components/ArticleFeed/ArticleFeed.scss';
@import '../components/deepLink/PrintCTAModal/PrintCTAModal.scss';
@import '../components/deepLink/SaveInAppBanner/SaveInAppBanner.scss';
@import '../components/ContentHeader/ContentHeader.scss';
@import '../components/ConnatixPlayer/ConnatixPlayer.scss';
@import '../components/EmptyBagModal/EmptyBagModal.scss';
@import '../components/FAQModal/FAQModal.scss';
@import '../components/FAQModal/InstructionItem/InstructionItem.scss';
@import '../components/FAQModal/QuestionsAccordion/QuestionsAccordion.scss';
@import '../components/feature/feature.scss';
@import '../components/FeatureCTA/FeatureCTA.scss';
@import '../components/FeaturedItem/FeaturedItem.scss';
@import '../components/Feed/Feed.scss';
@import '../components/FeedHeading/FeedHeading.scss';
@import '../components/FeedItem/FeedItem.scss';
@import '../components/FeedPageDescription/FeedPageDescription.scss';
@import '../components/footer/Footer/Footer.scss';
@import '../components/footer/FooterLinks/FooterLinks.scss';
@import '../components/footer/SocialButtons/SocialButtons.scss';
@import '../components/home/BestRecipes/BestRecipes.scss';
@import '../components/home/FeaturedImage/FeaturedImage.scss';
@import '../components/home/FeaturedArticle/FeaturedArticle.scss';
@import '../components/home/FeaturedPackage/FeaturedPackage.scss';
@import '../components/home/FeaturedRecipe/FeaturedRecipe.scss';
@import '../components/home/RecipeTips/RecipeTips.scss';
@import '../components/home/Shows/Shows.scss';
@import '../components/home/Stories/Stories.scss';
@import '../components/IngredientBreadcrumbs/IngredientBreadcrumbs.scss';
@import '../components/IngredientsList/IngredientsList.scss';
@import '../components/LinkWithBadge/LinkWithBadge.scss';
@import '../components/navbar/MobileSubmenu/MobileSubmenu.scss';
@import '../components/navbar/Nav/Nav.scss';
@import '../components/navbar/SearchBar/SearchBar.scss';
@import '../components/navbar/SecondaryNav/SecondaryNav.scss';
@import '../components/navbar/submenu/DesktopSubmenu/DesktopSubmenu.scss';
@import '../components/navbar/submenu/SubmenuCategory/SubmenuCategory.scss';
@import '../components/Newsletter/Newsletter.scss';
@import '../components/NumberInput/NumberInput.scss';
@import '../components/recipe/CookTimes/CookTimes.scss';
@import '../components/recipe/Ingredients/Ingredients.scss';
@import '../components/recipe/Nutrition/Nutrition.scss';
@import '../components/recipe/Preparation/Preparation.scss';
@import '../components/recipe/RecentRecipes/RecentRecipes.scss';
@import '../components/RecipeSubmitCTA/RecipeSubmitCTA.scss';
@import '../components/ShareBar/ShareBar.scss';
@import '../components/ShareButton/ShareButton.scss';
@import '../components/Shop/Shop.scss';
@import '../components/ShowMoreButton/ShowMoreButton.scss';
@import '../components/SponsoredPackage/SponsoredPackage.scss';
@import '../components/StoreLocator/ChangeStoreModal.scss';
@import '../components/StoreLocator/Location.scss';
@import '../components/StoreLocator/StoreLocator.scss';
@import '../components/TagBreadcrumbs/TagBreadcrumbs.scss';
@import '../components/TagFilters/TagFilters.scss';
@import '../components/tips/Tip/Tip.scss';
@import '../components/tips/TipsContent/TipsContent.scss';
@import '../components/tips/TipsHeader/TipsHeader.scss';
@import '../components/tips/TipsSummary/TipsSummary.scss';
@import '../components/UGCDisclaimer/UGCDisclaimer.scss';
@import '../components/UGCForm/UGCImageUploader/UGCImageUploader.scss';
@import '../components/UGCForm/UGCForm.scss';
@import '../components/Video/Video.scss';
@import '../components/WalmartLink/WalmartLink.scss';

@import '../pages/accessibility/accessibility.scss';
@import '../pages/tastyvalues/tastyvalues.scss';
@import '../pages/[feed]/feed-page.scss';
@import '../pages/article/[author]/article.scss';
@import '../pages/buy/buy.scss';
@import '../pages/compilation/compilation.scss';
@import '../pages/my-meal-plan-my-way/ceros.scss';
@import '../pages/ingredient/ingredient.scss';
@import '../pages/latest/latest.scss';
@import '../pages/guides/guides.scss';
@import '../pages/recipe/recipe.scss';
@import '../pages/search/search.scss';
@import '../pages/brand/brand.scss';
@import '../pages/index.scss'; // home page
@import '../pages/in-app-only/in-app-only.scss';

// Import solid at the end so it takes precedence
@import "base/solid-polyfill"; // solid classes

#my-cart-notification {
  position: fixed;
  top: 13px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  z-index: 10000;
  max-width: 75rem;

  @include solid-breakpoint("md") {
    top: 95px;

    > div {
      justify-content: right !important;
    }
  }

  > div {
    display: flex;
    justify-content: center;
  }
}
