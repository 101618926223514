.featured-recipe-container {
  transition: z-index 0s ease 0.2s;
  a {
    color: $color-text-dark;
  }
  .featured-recipe-content {
    position: relative;
    height: 100%;

    .featured-recipe_link {
      z-index: 1;

      &:hover {
        color: $color-primary-action;
        .featured-img {
          -webkit-transform: scale(1.05);
          transform: scale(1.05);
        }
      }
    }

    .desk_back {
      position: absolute;
      display: none;
      height: 108%;
      width: 100%;
      left: -8rem;
      top: -2%;

      @include solid-breakpoint("md") {
        display: block;
      }
    }

    .mobile_back {
      position: absolute;
      left: -2rem;
      top: 13rem;
      height: 60%;
      width: 80%;

      @include solid-breakpoint("md") {
        display: none;
      }
    }

    @media screen and (min-width: 450px) {
      .mobile_back {
        top: 45%;
      }
    }

    @media screen and (max-width: 320px) {
      .mobile_back {
        top: 25%;
      }
    }

    .featured-recipe-name {
      font-weight: $font-weight-extra-bold;
      font-size: 32px;
      line-height: 39px;
      z-index: 50;

      @include solid-breakpoint("sm") {
        font-size: 48px;
        line-height: 52px;
      }

      @include solid-breakpoint("lg") {
        font-size: 56px;
        line-height: 60px;
      }
    }

    .featured-img {
      img {
        object-fit: cover;
      }
    }

    .featured-img-wrapper {
      height: 100%;

      @include solid-breakpoint("md") {
        max-width: 728px;
        max-height: 728px;
      }
    }

    .featured-img-aspect-ratio-wrapper {
      @include aspect-ratio(1, 1);
    }
  }
}
