.feature-cta {
  min-height: 258px;

  @include solid-breakpoint("md") {
    min-height: 210px;
  }
}
.feature-cta__list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  li {
    width: 50%;
    margin-bottom: 8px;
    padding-right: 8px;
    @include solid-breakpoint("md") {
      width: calc(100%/3);
    }
    &:nth-child(2n) {
      padding-right: 0;
    }
    @include solid-breakpoint("md") {
      margin-bottom: 16px;
      &, &:nth-child(2n) {
        padding-right: 16px;
      }
      &:nth-child(3n) {
        padding-right: 0;
      }
    }
  }
}

.feature-cta__caret {
  width: 8px;
  height: 8px;
  margin-left: 5px;
}

.feature-cta__button {
  align-items: center;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 19px;
  height: 64px;
  transition: background-color .15s ease 0s;
  background-color: $color-cta-background;
  color: $color-text-cta;

  @include solid-breakpoint("sm") {
    justify-content: center;
  }

  @include solid-breakpoint("md") {
    font-size: 1.125rem;
    line-height: 22px;
  }

  .feature-cta__caret {
    fill: $color-text-cta;
  }

  &:hover {
    background-color: darken($color-cta-background, 10%);
    transition: background-color .15s ease 0s;
    color: darken($color-text-cta, 10%);
    .feature-cta__caret {
      fill: darken($color-text-cta, 10%);
    }
  }

  &:active {
    background-color: darken($color-cta-background, 20%);
    transition: background-color .15s ease 0s;
    color: darken($color-text-cta, 20%);
    .feature-cta__caret {
      fill: darken($color-text-cta, 20%);
    }
  }
}
