$shared-fonts-path: "~@buzzfeed/buzzblocks/fonts";

@font-face {
  font-family: "Proxima Nova";
  src: url("#{$shared-fonts-path}/ProximaNova-Reg-webfont.woff2")
      format("woff2"),
    url("#{$shared-fonts-path}/ProximaNova-Reg-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("#{$shared-fonts-path}/ProximaNova-Sbold-webfont.woff2")
      format("woff2"),
    url("#{$shared-fonts-path}/ProximaNova-Sbold-webfont.woff") format("woff");
  font-weight: $bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("#{$shared-fonts-path}/ProximaNova-Xbold-webfont.woff2")
      format("woff2"),
    url("#{$shared-fonts-path}/ProximaNova-Xbold-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

.regular {
  font-weight: 400;
}

.bold {
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

.extra-bold {
  font-weight: 800;
}

html {
  font-family: $sans;
}