.article__header {
  margin-bottom: 1.5rem;
}
.article__title {
  font-weight: $font-weight-extra-bold;
  font-size: 1.75rem;
  line-height: 1;
  margin-bottom: $space-1;
  @include solid-breakpoint("md") {
    font-size: 2.25rem;
    margin-bottom: $space-2;
  }
  @include solid-breakpoint("lg") {
    font-size: 3.5rem;
  }
}
.article__description {
  font-size: $text-3-size;
  line-height: $text-3-line-height;
  margin-bottom: $space-1;
  @include solid-breakpoint("md") {
    font-size: $text-2-size;
    line-height: $text-2-line-height;
    margin-bottom: $space-2;
  }
  a {
    color: $color-primary-action;
    &:hover {
      color: $color-primary-action-hover;
    }
  }
}
.article__byline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: $text-5-size;
  line-height: $text-4-line-height;
  text-decoration: none;
  margin-bottom: $space-1;
  width: fit-content;
  &:focus {
    outline-offset: 2px;
    outline: 1px solid $color-outline-gray;
  }

  .article__byline__avatar__img {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    margin-right: $space-1;
    object-fit: cover;
    @include solid-breakpoint("md") {
      width: 3.125rem;
      height: 3.125rem;
      min-width: 3.125rem;
    }
  }

  .article__byline__name {
    color: $color-text-dark;
    font-weight: $font-weight-extra-bold;
    &:hover {
      color: $color-primary-action;
    }
  }
  .article__byline__date {
    color: $color-text-gray;
    font-style: italic;
    white-space: nowrap;
    width: 100%;
  }
}
