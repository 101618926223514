.show-more {
  .button--tasty {
    background-color: #fff;
    border: none;
    color: #e40754;
    font-weight: 800;
    font-size: 22px;

    &:hover {
      color: #7d042e
    }
  }
}
