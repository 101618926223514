.buy-recipe-head__tips-icon {
  width: 14px;
  height: 14px;

  @media (max-width: 52rem) {
    width: 12px;
    height: 12px;
    margin-top: 3px;
  }
}
.tips-header-recipe {
  color: $color-primary-action;
  svg {
    stroke: $color-primary-action;
    fill: $color-primary-action;
  }
}
