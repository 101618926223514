@use "sass:math";
.feed-item {
  position: relative;
  display: inline-block;
  margin: $space-1;
  font-size: $text-4-size;
  vertical-align: top;
  width: calc-block-width(percentage(math.div(1,2))); // default to 2 wide

  &:hover {
    cursor: pointer;

    .feed-item__title {
      color: $color-primary-action;
    }
    .feed-item__brand {
      color: #222222;
    }

    .feed-item__img {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
  }
  @include solid-breakpoint("md") {
    margin-bottom: $space-2;
  }
}

.feed-item__scrollable-mobile {
  @media (max-width: $solid-md) {
    width: 100% !important; //override since 4 col starts at sm screens
    margin: 0;
    padding: $space-1 $space-2 $space-1 0;
    min-width: 140px;
  }
}

.feed-item__scrollable-mobile:first-child {
  @media (max-width: $solid-md) {
    margin-left: $space-2;
  }
}

.feed-item__scrollable-mobile:last-child {
  @media (max-width: $solid-md) {
    margin-right: 0;
  }
}

.feed-item__title {
  transition: color 0.15s ease-in-out;
  font-size: $text-4-size;
  font-weight: 800;
  color: #222222;
  line-height: 20px;
  @include solid-breakpoint("md") {
    font-size: $text-3-size;
    line-height: 22px;
  }
}

.feed-item__title-small {
  font-size: $text-4-size !important; //override the resize to 18px at md
  line-height: 20px !important;
}

.feed-item__brand {
  color: #222222;
  font-weight: normal;
  font-size: $text-5-size;
  line-height: 18px;
  @include solid-breakpoint("md") {
    font-size: $text-4-size;
    line-height: 20px;
  }
}

.feed-item__cooktime-label {
  padding-top: 12px;
  p {
    color: $color-feed-item-label;
    padding-top: 2px;
    padding-left: 6px;
  }
  svg {
    fill: $color-feed-item-label;
  }
}

.feed-item__placeholder {
  .feed-item__img-wrapper {
    background: $color-gray-background;
  }
  .feed-item__text-wrapper {
    background: $color-gray-background;
    padding: 0.75rem;
  }
}

.feed-item__img-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  padding-top: 100%;
  border-radius: 4px;
  margin-bottom: $space-1;
  z-index: 1;
}

.feed-item__img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: 50% 50%;
  transition: 0.2s ease-in-out;
  display: block;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.feed-item__right-text a {
  display: flex !important;

  .feed-item__img-wrapper {
    flex: 1;
    margin-bottom: 0;
    padding-top: calc(50% - 0.5rem);
  }
  .feed-item__text-wrapper {
    margin-left: $space-2;
    flex: 1;
  }
}

.feed-item__wide-image {
  width: calc-block-width(percentage(1));
  min-width: 280px;

  @include solid-breakpoint("md") {
    width: calc-block-width(percentage(math.div(1,2)));
    min-width: 0;
  }

  .feed-item__img-wrapper {
    @include aspect-ratio(16, 9);
  }
}

.feed-item__single-col {
  width: 100%;
  margin: 0 0 $space-2 0;
}

.feed-item__3-col {
  @include solid-breakpoint("md") {
    width: calc-block-width(percentage(math.div(1,3)));
  }
}

.feed-item__4-col {
  @include solid-breakpoint("sm") {
    width: calc-block-width(percentage(math.div(1,4)));
  }
}

.feed-item__6-col {
  max-width: 200px;
  @include solid-breakpoint("md") {
    width: calc-block-width(percentage(math.div(1,6)));
  }
}

.ugc-label {
  background-color: $color-feed-item-label;
  bottom: 0;
  left: 0;
  border-radius: 0 4px;
  color: #fff;
  font-weight: 400;
  font-size: $text-6-size;
  line-height: 18px;
  padding-top: 6px;
  position: absolute;
  text-align: center;
  width: 75px;
  height: 29px;

  @include solid-breakpoint("md") {
    font-size: $text-5-size;
    width: 90px;
    line-height: 20px;
    padding-top: 4px;
  }
}

.ugc-label__large--desktop {
  @include solid-breakpoint("md") {
    font-size: $text-4-size;
    width: 100px;
    line-height: 20px;
  }
}

.ugc-label__large-mobile {
  @media (max-width: $solid-md) {
    width: 90px;
    font-size: $text-5-size;
  }
}
