.buy-recipe {
  padding-top: .5rem;
}

.buy-recipe__title {
  padding-top: 0;

  @include solid-breakpoint("md") {
    padding-top: 1rem;
    font-size: 56px;
  }
}

.buy-recipe-head__icon {
  width: 13px;
  height: 13px;

  @include solid-breakpoint("md") {
    width: 15px;
    height: 15px;
  }
}

.buy-recipe-head__title {
  margin-bottom: 12px;

  @include solid-breakpoint('md') {
    font-size: 40px;
  }
}

.buy-recipe-branded__image-wrapper {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 4px;
  @include solid-breakpoint("md") {
    width: 3.5rem;
    height: 3.5rem;
  }
};

.buy-recipe-head__tips {
  margin-bottom: 4px;
}

.buy-recipe-head__link {
  justify-content: flex-start;
}

.buy-recipe-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-y: scroll;

  @media (max-width: 40rem) {  
    .ReactModal__Content {
      width: 100%;
      inset: 0px !important;
      height: 100%;
      padding: $space-3 0 !important;
    }  
  }

  .ReactModal__Content {
    left: 0 !important;
    @include solid-breakpoint("md") {
      max-width: 50%;
      margin: auto;
    }
  }
}

.buy-recipe-modal__close-button {
  position: absolute;
  top: .8rem;
  left: 0;
  @include solid-breakpoint("sm") {
    left: $space-2;
  }
}

.northfork-logo {
  display: flex;
  justify-content: center;
  border-top: 1px solid #f3f3f3;
  padding-top: 24px;
  margin-bottom: -8px;
}
