.accordion-item {
  border-top: 2px solid $fill-gray-lighter;

  .accordion-item__button {
    outline: none;
    min-height: 70px;
    padding: 24px 16px;
    cursor: pointer;  

    svg {
      right: 0;
    }
  }

  .accordion__icon {
    transition: transform .2s ease-in-out;

    &.accordion__icon--expanded {
      transform: rotate(180deg);
    }
  }
}

// This rule is counter-intuitive, but is required for the accordion animation
// accessible-accordion sets this attribute on item collapse, hiding it immediately
// we want the animate-height parent to take care of the display properties
.accordion-item__panel[hidden] {
  display: block !important;
}