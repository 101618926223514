.app-badge__image {
  height: 44px;
  min-height: 44px;
}

.app-badge__image--ios {
  width: 137px;
}

.app-badge__image--android {
  width: 147px;
}
