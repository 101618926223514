.best-recipes {
  position: relative;
  margin: 0 $space-2;
  max-width: 400px;
  border: 4px solid $color-gray-1;
  border-radius: 4px;
  flex-grow: 1;

  @include solid-breakpoint("sm") {
    margin: 0 auto;
  }

  @include solid-breakpoint("md") {
    max-width: 378px;
  }
}

.best-recipes__heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: $space-2;
  margin-bottom: $space-2;
}

.best-recipes__overline {
  z-index: 100;
  font-size: $text-3-size;
  font-weight: $font-weight-extra-bold;
  line-height: 22px;
  margin-bottom: $space-1;
}

.best-recipes__logo {
  z-index: 100;
  max-width: 150px;
  max-height: 76px;
}

.best-recipes__blob {
  margin: -0.25rem -0.25rem $space-2 -0.25rem;
  position: absolute;
  top: 0;
}
