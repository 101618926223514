.tips-summary {
  overflow: hidden;
  border-radius: 4px;
  border: 4px solid $color-gray-1;
  background: #fbfbf8;
  .tips-summary-text {
    overflow: hidden;
  }
  .tips-summary-icon {
    display: none;
    @include solid-breakpoint("lg") {
      display: block;
      float: left;
      margin-right: $space-2;
    }
  }
  .tips-summary-byline {
    display: block;
    padding-top: 12px;
    font-size: $text-6-size;
    color: $color-gray-7;
  }
}
