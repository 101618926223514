// =====================================
// =====================================
// =====================================
// DO NOT ADD EXTRA CLASSES TO THIS FILE
// =====================================
// =====================================
// =====================================

// This file contains the bare minimum viable Solid (a BuzzFeed style library -
// https://solid.buzzfeed.com/) classes for subbuzzes and ads, and existing Tasty styles.
// If other classes aren't supported on an individual subbuzz, ad, or other component,
// update the component code itself to not rely on a Solid class.

@use "sass:math";
// Overtime, ideally will refactor away from using solid, though that also requires
//  it to happen in adlib and SRA.  Those marked "SRA" are those
//  needed for integration with subbuzz_rendering_api
@import "variables";

blockquote {
  padding: 0;
  margin: 0;
}

//  12 Columm Grid
// -------------------------

.col { // SRA
  float: left;
}

.xs-col-2 { // SRA
  width: percentage(math.div(2, 12));
}
.xs-col-3 { // SRA
  width: percentage(math.div(3, 12));
}
.xs-col-4 {
  width: percentage(math.div(4, 12));
}
.xs-col-6 { // SRA
  width: percentage(math.div(6, 12));
}
.xs-col-8 { // SRA
  width: percentage(math.div(8, 12));
}
.xs-col-9 { // SRA
  width: percentage(math.div(9, 12));
}
.xs-col-10 { // SRA
  width: percentage(math.div(10, 12));
}
.xs-col-11 { // SRA
  width: percentage(math.div(11, 12));
}
.xs-col-12 { // SRA
  width: 100%;
}

.sm-col-3 { // SRA
  @include solid-breakpoint("sm") {
    width: percentage(math.div(3, 12));
  }
}
.sm-col-4 { // SRA
  @include solid-breakpoint("sm") {
    width: percentage(math.div(4, 12));
  }
}
.sm-col-6 {
  @include solid-breakpoint("sm") {
    width: percentage(math.div(6, 12));
  }
}
.sm-col-7 {
  @include solid-breakpoint("sm") {
    width: percentage(math.div(7, 12));
  }
}
.sm-col-8 { // SRA
  @include solid-breakpoint("sm") {
    width: percentage(math.div(8, 12));
  }
}
.sm-col-9 { // SRA
  @include solid-breakpoint("sm") {
    width: percentage(math.div(9, 12));
  }
}
.sm-col-12 {
  @include solid-breakpoint("sm") {
    width: 100%;
  }
}

.md-col-4 {
  @include solid-breakpoint("md") {
    width: percentage(math.div(4, 12));
  }
}
.md-col-5 { // SRA
  @include solid-breakpoint("md") {
    width: percentage(math.div(5, 12));
  }
}
.md-col-6 {
  @include solid-breakpoint("md") {
    width: percentage(math.div(6, 12));
  }
}
.md-col-7 {
  @include solid-breakpoint("md") {
    width: percentage(math.div(7, 12));
  }
}
.md-col-8 { // SRA
  @include solid-breakpoint("md") {
    width: percentage(math.div(8, 12));
  }
}
.md-col-12 {
  @include solid-breakpoint("md") {
    width: 100%;
  }
}
.md-offset-1 {
  @include solid-breakpoint("md") {
    margin-left: percentage(math.div(1, 12));
  }
}

.lg-col-4 { // SRA
  @include solid-breakpoint("lg") {
    width: percentage(math.div(4, 12));
  }
}
.lg-col-5 {
  @include solid-breakpoint("lg") {
    width: percentage(math.div(5, 12));
  }
}
.lg-col-8 { // SRA
  @include solid-breakpoint("lg") {
    width: percentage(math.div(8, 12));
  }
}

//  Block Grid
// -------------------------

.block-grid__item { // SRA
  display: inline-block;                ;
  margin: $space-1;
  font-size: $base-font-size;
  vertical-align: top;
}

.xs-block-grid-1 { // SRA
  font-size: 0;
  margin: (-$space-1);
  padding: 0;

  .block-grid__item {
    width: 100%;
  }
}

.sm-block-grid-3 { // SRA
  @include solid-breakpoint("sm") {
    font-size: 0;
    margin: (-$space-1);
    padding: 0;
  }

  .block-grid__item {
    width: calc(((1 / 3) * 100%) - 1rem);
  }
}

//  Flexbox
// -------------------------

.flex { // SRA
  display: flex;
}

.xs-flex-align-center { // SRA
  align-items: center;
}

.xs-flex-align-start {
  align-items: flex-start;
}

.xs-flex-align-end {
  align-items: flex-end 
}

.xs-flex-row {
  flex-direction: row;
}

.xs-flex-column { // SRA
  flex-direction: column;
}

.xs-flex-grow-1 { // SRA
  flex-grow: 1;
}

.xs-flex-grow-2 {
  flex-grow: 2;
}

.xs-flex-justify-end {
  justify-content: flex-end;
}

.xs-flex-justify-center { // SRA
  justify-content: center;
}

.xs-flex-justify-space-between { // SRA
  justify-content: space-between;
}

.xs-flex-shrink-0 { // SRA
  flex-shrink: 0;
}

.xs-flex-order-2 {
  order: 2;
}

.xs-flex-order-3 {
  order: 3;
}

.xs-flex-order-4 {
  order: 4;
}

.xs-flex-wrap {
  flex-wrap: wrap;
}

.xs-flex-nowrap {
  flex-wrap: nowrap;
}

.sm-flex-align-center {
  @include solid-breakpoint("sm") {
    align-items: center;
  }
}

.sm-flex-justify-space-between { // SRA
  @include solid-breakpoint("sm") {
    justify-content: space-between;
  }
}

.md-flex-align-center {
  @include solid-breakpoint("md") {
    align-items: center;
  }
}

.md-flex-row {
  @include solid-breakpoint("md") {
    flex-direction: row;
  }
}

.md-flex-column {
  @include solid-breakpoint("md") {
    flex-direction: column;
  }
}

.md-flex-order-1 {
  @include solid-breakpoint("md") {
    order: 1;
  }
}

.md-flex-wrap {
  @include solid-breakpoint("md") {
    flex-wrap: wrap;
  }
}

.md-flex-justify-start {
  @include solid-breakpoint("md") {
    justify-content: flex-start;
  }
}

.md-flex-justify-center {
  @include solid-breakpoint("md") {
    justify-content: center;
  }
}

.md-flex-justify-space-between {
  @include solid-breakpoint("md") {
    justify-content: space-between;
  }
}

.lg-flex-row {
  @include solid-breakpoint("lg") {
    flex-direction: row;
  }
}

//  Margin
// -------------------------

.xs-m0 { // SRA
  margin: 0;
}

.xs-mb0 { // SRA
  margin-bottom: 0;
}

.xs-mb05 { // SRA
  margin-bottom: $space-05;
}

.xs-mb1 { // SRA
  margin-bottom: $space-1;
}

.xs-mb2 { // SRA
  margin-bottom: $space-2;
}

.xs-mb3 { // SRA
  margin-bottom: $space-3;
}

.xs-mb4 { // SRA
  margin-bottom: $space-4;
}

.xs-mb5 {
  margin-bottom: $space-5;
}

.xs-ml0 { // SRA
  margin-left: 0;
}

.xs-ml05 { // SRA
  margin-left: $space-05;
}

.xs-ml1 { // SRA
  margin-left: $space-1;
}

.xs-ml2 {
  margin-left: $space-2;
}

.xs-mr0 {
  margin-right: 0;
}

.xs-mr05 {
  margin-right: $space-05;
}

.xs-mr1 { // SRA
  margin-right: $space-1;
}

.xs-mr2 { // SRA
  margin-right: $space-2;
}

.xs-mr4 {
  margin-right: $space-4;
}

.xs-mt0 { // SRA
  margin-top: 0;
}

.xs-mt05 {
  margin-top: $space-05;
}

.xs-mt1 { // SRA
  margin-top: $space-1;
}

.xs-mt2 { // SRA
  margin-top: $space-2;
}

.xs-mt3 { // SRA
  margin-top: $space-3;
}

.xs-mt4 {
  margin-top: $space-4;
}

.xs-mt5 {
  margin-top: $space-5;
}

.xs-mx05 { // SRA
  margin-left: $space-05;
  margin-right: $space-05;
}

.xs-mx1 {
  margin-left: $space-1;
  margin-right: $space-1;
}

.xs-mx2 { // SRA
  margin-left: $space-2;
  margin-right: $space-2;
}

.xs-mx-auto { // SRA
  margin-left: auto;
  margin-right: auto;
}

.xs-my2 {
  margin-top: $space-2;
  margin-bottom: $space-2;
}

.xs-my3 {
  margin-top: $space-3;
  margin-bottom: $space-3;
}

.xs-my4 {
  margin-top: $space-4;
  margin-bottom: $space-4;
}

.sm-mb0 { // SRA
  @include solid-breakpoint("sm") {
    margin-bottom: 0;
  }
}

.sm-mb2 { // SRA
  @include solid-breakpoint("sm") {
    margin-bottom: $space-2;
  }
}

.sm-mb3 { // SRA
  @include solid-breakpoint("sm") {
    margin-bottom: $space-3;
  }
}

.sm-mb4 {
  @include solid-breakpoint("sm") {
    margin-bottom: $space-4;
  }
}

.sm-mb5 {
  @include solid-breakpoint("sm") {
    margin-bottom: $space-5;
  }
}

.sm-mr3 { // SRA
  @include solid-breakpoint("sm") {
    margin-right: $space-3;
  }
}

.sm-mt0 { // SRA
  @include solid-breakpoint("sm") {
    margin-top: 0;
  }
}

.sm-mt05 { // SRA
  @include solid-breakpoint("sm") {
    margin-top: $space-05;
  }
}

.sm-mx0 {
  @include solid-breakpoint("sm") {
    margin-left: 0;
    margin-right: 0;
  }
}

.sm-mx2 {
  @include solid-breakpoint("sm") {
    margin-left: $space-2;
    margin-right: $space-2;
  }
}

.md-m0 {
  @include solid-breakpoint("md") {
    margin: 0;
  }
}

.md-m4 { // SRA
  @include solid-breakpoint("md") {
    margin: $space-4;
  }
}

.md-mb0 {
  @include solid-breakpoint("md") {
    margin-bottom: 0;
  }
}

.md-mb1 {
  @include solid-breakpoint("md") {
    margin-bottom: $space-1;
  }
}

.md-mb2 {
  @include solid-breakpoint("md") {
    margin-bottom: $space-2;
  }
}

.md-mb3 {
  @include solid-breakpoint("md") {
    margin-bottom: $space-3;
  }
}

.md-mb4 { // SRA
  @include solid-breakpoint("md") {
    margin-bottom: $space-4;
  }
}

.md-mb5 {
  @include solid-breakpoint("md") {
    margin-bottom: $space-5;
  }
}

.md-mb6 {
  @include solid-breakpoint("md") {
    margin-bottom: $space-6;
  }
}

.md-mt0 {
  @include solid-breakpoint("md") {
    margin-top: 0;
  }
}

.md-mt05 {
  @include solid-breakpoint("md") {
    margin-top: $space-05;
  }
}

.md-mt1 {
  @include solid-breakpoint("md") {
    margin-top: $space-1;
  }
}

.md-mt2 {
  @include solid-breakpoint("md") {
    margin-top: $space-2;
  }
}

.md-mt3 {
  @include solid-breakpoint("md") {
    margin-top: $space-3;
  }
}

.md-mt4 {
  @include solid-breakpoint("md") {
    margin-top: $space-4;
  }
}

.md-mt5 {
  @include solid-breakpoint("md") {
    margin-top: $space-5;
  }
}

.md-mt6 {
  @include solid-breakpoint("md") {
    margin-top: $space-6;
  }
}

.md-mr0 {
  @include solid-breakpoint("md") {
    margin-right: 0;
  }
}

.md-mr1 {
  @include solid-breakpoint("md") {
    margin-right: $space-1;
  }
}

.md-mr2 {
  @include solid-breakpoint("md") {
    margin-right: $space-2;
  }
}

.md-mr3 {
  @include solid-breakpoint("md") {
    margin-right: $space-3;
  }
}

.md-mr6 {
  @include solid-breakpoint("md") {
    margin-right: $space-6;
  }
}

.md-ml0 {
  @include solid-breakpoint("md") {
    margin-left: 0;
  }
}

.md-ml1 {
  @include solid-breakpoint("md") {
    margin-left: $space-1;
  }
}

.md-ml4 {
  @include solid-breakpoint("md") {
    margin-left: $space-4;
  }
}

.md-mx0 {
  @include solid-breakpoint("md") {
    margin-left: 0;
    margin-right: 0;
  }
}

.md-mx1 {
  @include solid-breakpoint("md") {
    margin-left: $space-1;
    margin-right: $space-1;
  }
}

.md-my0 {
  @include solid-breakpoint("md") {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.md-my5 {
  @include solid-breakpoint("md") {
    margin-top: $space-5;
    margin-bottom: $space-5;
  }
}

.lg-mb1 {
  @include solid-breakpoint("lg") {
    margin-bottom: $space-1;
  }
}

.lg-mb2 {
  @include solid-breakpoint("lg") {
    margin-bottom: $space-2;
  }
}

.lg-mb3 {
  @include solid-breakpoint("lg") {
    margin-bottom: $space-3;
  }
}

.lg-mb4 {
  @include solid-breakpoint("lg") {
    margin-bottom: $space-4;
  }
}

.lg-mt2 {
  @include solid-breakpoint("lg") {
    margin-top: $space-2;
  }
}

.lg-mt3 {
  @include solid-breakpoint("lg") {
    margin-top: $space-3;
  }
}

.lg-mt6 {
  @include solid-breakpoint("lg") {
    margin-top: $space-6;
  }
}

.lg-mr1 {
  @include solid-breakpoint("lg") {
    margin-right: $space-1;
  }
}

.lg-my5 {
  @include solid-breakpoint("lg") {
    margin-top: $space-5;
    margin-bottom: $space-5;
  }
}

//  Padding
// -------------------------

.xs-p0 { // SRA
  padding: 0;
}

.xs-p1 {
  padding: $space-1;
}

.xs-p2 { // SRA
  padding: $space-2;
}

.xs-pb05 {
  padding-bottom: $space-05;
}

.xs-pb1 { // SRA
  padding-bottom: $space-1;
}

.xs-pb2 {
  padding-bottom: $space-2;
}

.xs-pb3 {
  padding-bottom: $space-3;
}

.xs-pb4 {
  padding-bottom: $space-4;
}

.xs-pl05 {
  padding-left: $space-05;
}

.xs-pl1 {
  padding-left: $space-1;
}

.xs-pl2 { // SRA
  padding-left: $space-2;
}

.xs-pl3 {
  padding-left: $space-3;
}

.xs-pr0 { // SRA
  padding-right: 0;
}

.xs-pr1 { // SRA
  padding-right: $space-1;
}

.xs-pr3 {
  padding-right: $space-3;
}

.xs-pr5 {
  padding-right: $space-5;
}

.xs-pt0 { // SRA
  padding-top: 0;
}

.xs-pt1 {
  padding-top: $space-1;
}

.xs-pt2 {
  padding-top: $space-2;
}

.xs-pt4 {
  padding-top: $space-4;
}

.xs-pt5 {
  padding-top: $space-5;
}

.xs-px1 {
  padding-left: $space-1;
  padding-right: $space-1;
}

.xs-px2 { // SRA
  padding-left: $space-2;
  padding-right: $space-2;
}

.xs-px3 {
  padding-left: $space-3;
  padding-right: $space-3;
}

.xs-px4 { // SRA
  padding-left: $space-4;
  padding-right: $space-4;
}

.xs-px5 {
  padding-left: $space-5;
  padding-right: $space-5;
}

.xs-py05 {
  padding-top: $space-05;
  padding-bottom: $space-05;
}

.xs-py1 { // SRA
  padding-top: $space-1;
  padding-bottom: $space-1;
}

.xs-py2 {
  padding-top: $space-2;
  padding-bottom: $space-2;
}

.xs-py3 { // SRA
  padding-top: $space-3;
  padding-bottom: $space-3;
}

.xs-py5 {
  padding-top: $space-5;
  padding-bottom: $space-5;
}

.sm-pl1 { // SRA
  @include solid-breakpoint("sm") {
    padding-left: $space-1;
  }
}

.sm-pl2 { // SRA
  @include solid-breakpoint("sm") {
    padding-left: $space-2;
  }
}

.sm-pr0 { // SRA
  @include solid-breakpoint("sm") {
    padding-right: 0;
  }
}

.sm-pr3 { // SRA
  @include solid-breakpoint("sm") {
    padding-right: $space-3;
  }
}

.sm-pt4 {
  @include solid-breakpoint("sm") {
    padding-top: $space-4;
  }
}

.sm-pb4 {
  @include solid-breakpoint("sm") {
    padding-bottom: $space-4;
  }
}

.sm-px2 {
  @include solid-breakpoint("sm") {
    padding-right: $space-2;
    padding-left: $space-2;
  }
}

.sm-px4 {
  @include solid-breakpoint("sm") {
    padding-right: $space-4;
    padding-left: $space-4;
  }
}

.md-p1 {
  @include solid-breakpoint("md") {
    padding: $space-1;
  }
}

.md-p4 { // SRA
  @include solid-breakpoint("md") {
    padding: $space-4;
  }
}

.md-pt0 {
  @include solid-breakpoint("md") {
    padding-top: 0;
  }
}

.md-pt05 {
  @include solid-breakpoint("md") {
    padding-top: $space-05;
  }
}

.md-pt3 {
  @include solid-breakpoint("md") {
    padding-top: $space-3;
  }
}

.md-pr2 {
  @include solid-breakpoint("md") {
    padding-right: $space-2;
  }
}

.md-pr3 {
  @include solid-breakpoint("md") {
    padding-right: $space-3;
  }
}

.md-pr5 {
  @include solid-breakpoint("md") {
    padding-right: $space-5;
  }
}

.md-pr6 {
  @include solid-breakpoint("md") {
    padding-right: $space-6;
  }
}

.md-pb1 {
  @include solid-breakpoint("md") {
    padding-bottom: $space-1;
  }
}

.md-pb2 {
  @include solid-breakpoint("md") {
    padding-bottom: $space-2;
  }
}

.md-pl2 {
  @include solid-breakpoint("md") {
    padding-left: $space-2;
  }
}

.md-pl4 { // SRA
  @include solid-breakpoint("md") {
    padding-left: $space-4;
  }
}

.md-px0 {
  @include solid-breakpoint("md") {
    padding-right: 0;
    padding-left: 0;
  }
}

.md-px2 {
  @include solid-breakpoint("md") {
    padding-right: $space-2;
    padding-left: $space-2;
  }
}

.md-px4 {
  @include solid-breakpoint("md") {
    padding-right: $space-4;
    padding-left: $space-4;
  }
}

.lg-pr6 {
  @include solid-breakpoint("lg") {
    padding-right: $space-6;
  }
}

.lg-pb05 {
  @include solid-breakpoint("lg") {
    padding-bottom: $space-05;
  }
}

.lg-pb1 {
  @include solid-breakpoint("lg") {
    padding-bottom: $space-1;
  }
}

.lg-pl05 {
  @include solid-breakpoint("lg") {
    padding-left: $space-05;
  }
}

//  Absolute Positioning
// -------------------------

.xs-b0 { // SRA
  bottom: 0;
}

.xs-r0 { // SRA
  right: 0;
}

.xs-t0 { // SRA
  top: 0;
}

.xs-l0 {
  left: 0;
}

.md-b2 {
  @include solid-breakpoint("md") {
    bottom: $space-2;
  }
}

//  Positioning
// -------------------------

.xs-absolute { // SRA
  position: absolute;
}

.xs-relative { // SRA
  position: relative;
}

.xs-z1 { // SRA
  z-index: $z1;
}

.xs-z2 { // SRA
  z-index: $z2;
}

.xs-z3 { // SRA
  z-index: $z3;
}

.xs-z4 {
  z-index: $z4;
}

.xs-float-left { // SRA
  float: left;
}

.xs-float-right { // SRA
  float: right;
}

.md-float-right { // SRA
  @include solid-breakpoint("md") {
    float: right;
  }
}

.md-absolute {
  @include solid-breakpoint("md") {
    position: absolute;
  }
}

.md-relative {
  @include solid-breakpoint("md") {
    position: relative;
  }
}

//  Display
// -------------------------

.xs-hide { // SRA
  display: none;
}

.xs-inline {
  display: inline;
}

.xs-block { // SRA
  display: block;
}

.xs-flex { // SRA
  display: flex;
}

.xs-inline-block { // SRA
  display: inline-block;
}

.xs-fit {
  max-width: 100%;
}

.sm-hide { // SRA
  @include solid-breakpoint("sm") {
    display: none;
  }
}

.sm-block { // SRA
  @include solid-breakpoint("sm") {
    display: block;
  }
}

.sm-flex { // SRA
  @include solid-breakpoint("sm") {
    display: flex;
  }
}

.sm-inline-block { // SRA
  @include solid-breakpoint("sm") {
    display: inline-block;
  }
}

.md-hide { // SRA
  @include solid-breakpoint("md") {
    display: none;
  }
}

.md-block { // SRA
  @include solid-breakpoint("md") {
    display: block;
  }
}

.md-flex {
  @include solid-breakpoint("md") {
    display: flex;
  }
}

.md-inline-block {
  @include solid-breakpoint("md") {
    display: inline-block;
  }
}

.md-width-auto {
  @include solid-breakpoint("md") {
    width: auto;
  }
}

.lg-hide {
  @include solid-breakpoint("lg") {
    display: none;
  }
}

.lg-block {
  @include solid-breakpoint("lg") {
    display: block;
  }
}

//  Vertical Align
// -------------------------

.sm-align-middle { // SRA
  @include solid-breakpoint("sm") {
    vertical-align: middle;
  }
}

//  Typography
// -------------------------

.italic { // SRA
  font-style: italic;
}

.caps { // SRA
  text-transform: uppercase;
}

.list-unstyled {
  margin-left:  0;
  padding-left: 0;
  list-style: none;
}

.decoration-underline {
  text-decoration: underline;
}

.xs-text-1 {
  font-size: $text-1-size;
  line-height: $text-1-line-height;
}

.xs-text-2 { // SRA
  font-size: $text-2-size;
  line-height: $text-2-line-height;
}

.xs-text-3 { // SRA
  font-size: $text-3-size;
  line-height: $text-3-line-height;
}

.xs-text-4 { // SRA
  font-size: $text-4-size;
  line-height: $text-4-line-height;
}

.xs-text-5 { // SRA
  font-size: $text-5-size;
  line-height: $text-5-line-height;
}

.xs-text-6 { // SRA
  font-size: $text-6-size;
  line-height: $text-6-line-height;
}

.xs-text-center { // SRA
  text-align: center;
}

.xs-text-right {
  text-align: right;
}

.sm-text-left { // SRA
  @include solid-breakpoint("sm") {
    text-align: left;
  }
}

.sm-text-2 {
  @include solid-breakpoint("sm") {
    font-size: $text-2-size;
    line-height: $text-2-line-height;
  }
}

.sm-text-4 {
  @include solid-breakpoint("sm") {
    font-size: $text-4-size;
    line-height: $text-4-line-height;
  }
}

.md-text-1 { // SRA
  @include solid-breakpoint("md") {
    font-size: $text-1-size;
    line-height: $text-1-line-height;
  }
}

.md-text-2 {
  @include solid-breakpoint("md") {
    font-size: $text-2-size;
    line-height: $text-2-line-height;
  }
}

.md-text-3 { // SRA
  @include solid-breakpoint("md") {
    font-size: $text-3-size;
    line-height: $text-3-line-height;
  }
}

.md-text-4 {
  @include solid-breakpoint("md") {
    font-size: $text-4-size;
    line-height: $text-4-line-height;
  }
}

.md-text-5 {
  @include solid-breakpoint("md") {
    font-size: $text-5-size;
    line-height: $text-5-line-height;
  }
}

.md-text-center { // SRA
  @include solid-breakpoint("md") {
    text-align: center;
  }
}

.md-text-right {
  @include solid-breakpoint("md") {
    text-align: right;
  }
}

.lg-text-2 {
  @include solid-breakpoint("lg") {
    font-size: $text-2-size;
    line-height: $text-2-line-height;
  }
}

.nowrap { // SRA
  white-space: nowrap;
}

//  Height
// -------------------------
.xs-full-height { // SRA
  height: 100%;
}

.xs-height-auto {
  height: auto;
}


//  Overflow
// -------------------------

.xs-overflow-hidden { // SRA
  overflow: hidden;
}

//  Colors
// -------------------------

.link-gray { // SRA
  color: $color-black;

  path {
    fill: $color-black;
  }

  &:hover {
    color: $color-primary-action;

    path {
      transition: fill .15s ease 0s;
      fill: $color-primary-action;
      cursor: pointer;
    }
  }
}

.svg-white { // SRA
  fill: $color-white;
}

.text-gray { // SRA
  color: $color-black;
}

.text-gray-lighter,
.text-gray-lightest { // SRA
  color: $color-text-gray;
}

.text-white { // SRA
  color: $color-white;
}

.fill-gray-lighter { // SRA
  background-color: $color-gray-background;
}

.fill-white { // SRA
  background-color: $color-white-background;
}

//  SVG
// -------------------------

.svg-1 {
  vertical-align: middle;
  width: $text-1-size;
  height: $text-1-size;
}

.svg-2 { // SRA
  vertical-align: middle;
  width: $text-2-size;
  height: $text-2-size;
}

.svg-3 {
  vertical-align: middle;
  width: $text-3-size;
  height: $text-3-size;
}

.svg-4 { // SRA
  vertical-align: middle;
  width: $text-4-size;
  height: $text-4-size;
}

.svg-5 {
  vertical-align: middle;
  width: $text-5-size;
  height: $text-5-size;
}

//  Borders
// -------------------------

.xs-border { // SRA
  border: $border
}

.xs-border-none { // SRA
  border: none;
}

//  Buttons
// -------------------------
$buzzfeed-button-color: #0f65ef;

.button { // SRA
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  appearance: none;
  user-select: none;
  font-family: inherit;
  padding: .5rem .875rem;
  font-size: $text-4;
  line-height: 1.5rem;
  border-radius: $border-radius;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  background-color: $buzzfeed-button-color;
  color: $color-white;
  border-color: $buzzfeed-button-color;
  transition: background-color .1s ease 0s;

  &:hover,
  &:focus {
    background-color: darken($buzzfeed-button-color, 20%);
  }

  &:active {
    background-color: darken($buzzfeed-button-color, 35%);
  }
}

.button--icon > svg { // SRA
  width: 1rem;
  height: 1rem;
  fill: $color-white;
  position: relative;
  top: .125rem;
  margin-right: .5rem;
}

.button--icon.button--small > svg { // SRA
  width: .875rem;
  height: .875rem;
  position: relative;
  margin-right: .3125rem;
}

.button--small { // SRA
  @extend .button;
  padding: .3125rem .625rem;
  font-size: $text-5-size;
  line-height: 1.25rem;
}

.button--transparent { // SRA
  @extend .button;
  background-color: transparent;
  color: $buzzfeed-button-color;
  border-color: transparent;
  border: 1px solid transparent;
}

.button--transparent:not(.button--disabled):not(:disabled):hover { // SRA
  background-color: transparent;
  color: #093c8f;
}

//  Forms
// -------------------------

.text-input,
.select { // SRA
  font-family: inherit;
  background: $color-white;
  font-size: $text-4-size;
  line-height: 1.5rem;
  padding: .5rem .75rem;
  border: 1px solid $color-input-border;
  border-radius: $border-radius;
}

.text-input:disabled,
.text-input--small:disabled { // SRA
  opacity: 0.3;
}

.select {
  background-image: svg-background($down-caret);
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) center;
  background-size: .6875rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 2.5rem;
}

.select--small {
  font-size: $text-5-size;
  line-height: 1.25rem;
  padding: 0.3125rem .625rem;
  padding-right: 2rem;
  background-position: calc(100% - .875rem) center;
  background-size: .5rem;
}

.checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px; margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Override for Chrome bug where height collapses on toggle
  height: 0px;

  + label {
    font-size: $text-5-size;
    cursor: pointer;
    line-height: 1.25rem;
    display: block;
    &:before {
      content: "";
      display: inline-block;
      width: .75rem;
      height: .75rem;
      margin-right: .375rem;
      position: relative;
      bottom: -1px;
      background-color: $color-white-background;
      border: 1px solid $color-input-border;
    }

    &:before { border-radius: $border-radius; }
  }

  &:disabled + label {
    opacity: $opacity-disabled;
  }
}

.checkbox:checked + label:before {
  background-image: svg-background($checkmark);
  background-repeat: no-repeat;
  background-position: center;
  background-color: $fill-blue;
  background-size: .5rem;
  border-style: none;
}

input:focus + label::before {
  box-shadow: 0 0 0px 2px #7a9ff3;
}

// Components & Misc
// -------------------------

.card { // SRA
  box-shadow: 0 1px 1px rgba(173, 168, 168, .1);
  border: 1px solid rgba(0, 0, 0, .1);
  background-color: $color-white;
  border-radius: $border-radius;
}

.circle { // SRA
  border-radius: 50%;
}

.rounded { // SRA
  border-radius: $border-radius;
}

.clearfix:before,
.clearfix:after { // SRA
  content: " ";
  display: table;
}

.clearfix:after { // SRA
  clear: both;
}

// Unsupported fallback
// -------------------------
.enhanced-ux { // SRA
  .js-unsupported-fallback {
    display: none;
  }
}

.js-hidden { // SRA
  display: none !important;
}
