.number-input {
  border: 2px solid #f4f4f4;
  border-radius: 4px;
  padding: 2px 0;

  input {
    @include input-reset;
    text-align: center;
    width: 32px;
    &:focus {
      border: 2px solid #2260C5;
    }
  }

  button {
    @include input-reset;
    width: 28px;
    display: inline-block;

    svg {
      fill: $color-tasty-pink-5;
    }

    &:disabled svg {
      fill: #dadada;
    }

    &:focus {
      border: 2px solid #2260C5;
    }
  }

  .decrease {
    &:focus {
      border-radius: 4px 0px 0px 4px;
    }
  }

  .increase {
    &:focus {
      border-radius: 0px 4px 4px 0px;
    }
  }
}

.number-input--onfocus.number-input--active {
  position: absolute;
  top: -4px;
  right: 12px;
  display: flex;
  background: $color-white-background;
  z-index: 100;
}
