.search-page {
  margin: 0 auto;
  width: 100%;
}

.search__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 $space-2 $space-1 $space-2;
  position: relative;
  @include solid-breakpoint("md") {
    margin-bottom: $space-3;
  }
}
.search__header-terms {
  font-weight: $font-weight-extra-bold;
  font-size: $text-1-size;
  line-height: 1.2;
  margin-right: $space-2;

  @include solid-breakpoint("md") {
    margin-bottom: $space-3;
    font-size: 3rem;
  }
}

.search__header-count {
  color: $color-text-gray;
  font-size: $text-4-size;
  line-height: 1.3;
  @include solid-breakpoint("sm") {
    font-size: $text-2-size;
    line-height: 1.2;
  }
}

.search__filters-toggle {
  font-weight: $font-weight-extra-bold;
  font-size: $text-4-size;
  line-height: $text-4-line-height;
  display: flex;
  align-items: center;
}

.search__sort-desktop {
  margin-right: $space-4;
  display: none;
  @include solid-breakpoint("md") {
    display: block;
  }
}

.search__sort-mobile {
  margin: 20px 0px $space-1 $space-2;
  display: block;
  @include solid-breakpoint("md") {
    display: none;
  }
}

.show-more {
  padding-bottom: $space-2;
}

.feed__container {
  margin: 0 $space-1;
}

.empty-search {
  text-align: center;
  width: 83.3333%;
  margin: $space-3 auto $space-4;
  @include solid-breakpoint("sm") {
    margin-top: $space-6;
  }
}

.empty-search__icon {
  font-size: 3rem;
  line-height: 1.2;
}

.empty-search__text {
  font-size: $text-1-size;
  line-height: 1.2;
  font-weight: 800;
  @include solid-breakpoint("md") {
    font-size: 3rem;
  }
}
.empty-search li {
  margin-top: $space-2;
}
.empty-search__more {
  margin-top: $space-2;
  font-size: $text-3-size;
  @include solid-breakpoint("md") {
    font-size: $text-2-size;
  }
}

.search-results__content {
  position: relative;
}

.filter-results__loading {
  position: absolute;
  background: #ffffff;
  z-index: 1;
  top: 0;
  height: 100%;
  width: 100%;

  .loading-donut {
    padding-top: $space-2;
  }
}

.show-more {
  margin: 0 $space-2;
}
