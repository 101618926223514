@mixin button-style($fill-color, $text-color, $secondary-text-color:$fill-color, $secondary-hover-text-color:$text-color) {
  //base style
  background-color: $fill-color;
  color: $text-color;
  border-color: $secondary-text-color;
  border: 1px solid transparent;
  transition: background-color .1s ease 0s;

  //only have hover styles if button is not disabled
  &:not(.button--disabled):not(:disabled) {
    &:hover {
      background-color: darken($fill-color, 20%);;
      transition: background-color .15s ease 0s;
    }

    &:active { background-color: darken($fill-color, 35%); }
  }
}

.button--base {
  font-family: inherit;
  font-size: $text-4;
  padding: .5rem .875rem;
  line-height: 1.5rem;
  border-radius: 3px;
  text-decoration: none;
  display: inline-block;
  border: 1px solid transparent;
  text-align: center;
  transition: all .15s ease 0s;
  cursor: pointer;

  &:hover, &:active {
    transition: all .15s ease 0s;
  }
}

.button--clear {
  background-color: transparent;
  border-color: transparent;
  border: 1px solid transparent;
  padding: 0px;
  &:active {
    outline: 0;
  }
}

.button--round {
  background-image: none;
  @extend .button--base;
  border-left: 0;
  border-right: 0;
  border-radius: 50%;
}

.button--tasty {
  @extend .button--base;
  background-color: $color-primary-action;
  border-color: $color-primary-action;
  color: $color-text-white;

  &:hover {
    background-color: $color-primary-action-hover;
    border-color: $color-primary-action-hover;
    color: $color-text-white;
  }
  &:active {
    background-color: $color-primary-action-pressed;
    border-color: $color-primary-action-pressed;
    color: $color-text-white;
  }

  svg {
    fill: $color-text-white;
  }
}

.button--tasty.button--tasty-secondary {
  background-color: $color-white-background;
  border-color: $color-primary-action;
  border-width: 2px;
  color: $color-primary-action;

  svg {
    fill: $color-primary-action;
  }

  &:hover {
    background-color: $color-white-background;
    border-color: $color-primary-action-hover;
    color: $color-primary-action-hover;

    svg {
      fill: $color-primary-action-hover;
    }
  }
  &:active {
    background-color: $color-white-background;
    border-color: $color-primary-action-pressed;
    color: $color-primary-action-pressed;

    svg {
      fill: $color-primary-action-pressed;
    }
  }
}

.button--tasty-alternate {
  @extend .button--base;
  background-color: $color-primary-action-alternate;
  border-color: $color-primary-action-alternate;
  color: $color-text-dark;

  &:hover {
    background-color: $color-primary-action-alternate-hover;
    border-color: $color-primary-action-alternate-hover;
    color: $color-text-dark;
  }
  &:active {
    background-color: $color-primary-action-alternate-pressed;
    border-color: $color-primary-action-alternate-pressed;
    color: $color-text-dark;
  }
}

.button--tasty-link {
  border: none;
}

.button--disabled,
.button:disabled {
  opacity: 0.3;

  &:hover {
    cursor: default;
    transition: none;
  }
}
