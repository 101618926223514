.link-with-badge {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    vertical-align: middle;
    width: 0.75rem;
    height: 0.75rem;
  }
}
