.empty-bag-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.9);
  cursor: auto;

  .ReactModal__Content {
    width: 303px;
    height: 182px;
    inset: 0px !important;
    padding: $space-3 $space-3 $space-2 !important;
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    overflow: hidden !important;

    .empty-bag-modal {
      svg {
        fill: $color-tasty-pink-5;
      }

      .empty-bag-modal__close-button {
        position: absolute;
        right: 8px;
        top: 10px;

        .empty-bag-modal__close-button-svg {
          &:hover {
            fill: $color-tasty-pink-8;
          }
        }
      }

      button {
        @include button-reset;
      }

      .btn {
        -webkit-appearance: none;
      }
    }
  }
}
