.article-page {
  margin: 0 auto;
  margin-top: $space-2;
  width: 100%;
  padding: $space-1 0;
}

.article__primary-content {
  padding-left: $space-2;
  padding-right: $space-2;
  @include solid-breakpoint("lg") {
    max-width: calc(100% - 360px);
  }
}

.article__secondary-content {
  @include solid-breakpoint("lg") {
    flex-shrink: 2;
    min-width: 352px;
    max-width: 352px;
    padding-right: $space-1;
  }
}
