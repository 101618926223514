.breadcrumb_wrapper {
  display: flex;
  position: relative;
  overflow-x: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
}

.breadcrumb_wrapper::before,
.breadcrumb_wrapper::after {
  content: '';
  position: sticky;
  top: 0;
  bottom: 0;
  min-width: $space-2;
  z-index: 1;
}

.breadcrumb_wrapper::before {
  left: 0;
  background-image: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.breadcrumb_wrapper::after {
  right: 0;
  background-image: linear-gradient(-90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.breadcrumb_item {
  @extend .tag-filters__filter;

  white-space: nowrap;
}
