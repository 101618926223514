@import '../FeedItem/FeedItem.scss';

.featured-item {
  transition: z-index 0s ease .2s;
  z-index: 1;
  .highlight {
    background: $color-highlight-background;
    box-shadow: .5rem 0 0 $color-highlight-background, -.5rem 0 0 $color-highlight-background;
  }
  @media (min-width: $solid-sm) {
    .highlight {
      box-shadow: 1rem 0 0 $color-highlight-background, -1rem 0 0 $color-highlight-background;
    }
  }
  margin-bottom: 1.5rem;

  &:hover {
    .featured-item__img {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
    .featured-item__title {
      color: $color-text-white;
    }
  }
}

.featured-item__header {
  color: $color-text-dark;
  padding: $space-1 $space-2 $space-1 $space-2;
  @include solid-breakpoint("sm") {
    padding: $space-1 0 $space-1 0;
  }
}

.featured-item__img-wrapper {
  @extend .feed-item__img-wrapper;
  padding-top: 0;
  margin-left: auto;
}

.featured-item__aspect-ratio-wrapper {
  background: $color-gray-background;
}

.featured-item__img {
  @extend .feed-item__img;
  picture {
    display: flex;
  }
}

.featured-item__title {
  font-size: 28px;
  color: $color-text-white;

  @media (min-width: $solid-sm) {
    font-size: 56px;
  }

  @media (min-width: $solid-md) {
    line-height: normal;
  }

  @media (min-width: $solid-lg) {
    font-size: 64px;
  }
}

.featured-item__title-wrapper {
  max-width: $content-width;
  z-index: 1;
  &:hover + img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

.branded--title {
  color: $color-text-white;
}
