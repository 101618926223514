.nutrition-info {
  a:hover {
    color: $color-text-gray;
  }

  .walmart-link {
    img {
      background-color: #fff;
      height: 14px;
      margin-left: 4px;
      margin-bottom: 1px;
    }
  }
}

.plus {
  padding-bottom: 2px;
}
