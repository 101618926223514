.feed-page {
  margin: 0 auto;
  padding-top: $space-2;
  width: 100%;

  @include solid-breakpoint("md") {
    padding-top: $space-3;
  }
  .feed__heading {
    margin-left: 0;
    margin-top: $space-05;
  }
  .feed__container {
    margin: 0;
  }
  .show-more {
    margin: 0 $space-1;
  }
}

.feed-page__header {
  margin-bottom: $space-3;
  @include solid-breakpoint("md") {
    margin-bottom: 2.5rem;
  }
  &.feed-page__header--ugc-submission,
  &.feed-page__header--contest-submission {
    margin-bottom: 0;
  }
}

.feed-page__heading {
  font-weight: $font-weight-extra-bold;
  margin-right: $space-2;
  display: inline-block;
  font-size: $text-1-size;
  line-height: $text-1-line-height;
  @include solid-breakpoint("md") {
    font-size: 3rem;
  }
  &.feed-page__heading--ugc-submission,
  &.feed-page__heading--contest-submission {
    margin-bottom: 0.75rem;
    @include solid-breakpoint("md") {
      font-size: 3.5rem;
    }
  }
}

.feed-page__heading-count {
  color: $color-text-gray;
  font-size: $text-2-size;
  line-height: $text-2-line-height;
  display: inline-block;
}

.feed-page__breadcrumbs {
  margin-bottom: $space-3;
}

