.nav__submenu-category-wrapper {
  width: 50%;
}

.nav__submenu-category {
  width: 100%;
  padding-bottom: $space-4;
  padding-left: 0;

  @include solid-breakpoint("md") {
    width: auto;
    padding-bottom: 0;
  }
}

.nav__submenu-item {
  padding: 2px 2px;
  margin: 4px 0;
  display: inline-block;
  border: 1px solid transparent;
  color: $color-text-dark;
  &:hover {
    color: $color-primary-action;
  }
  @include solid-breakpoint("md") {
    padding: 2px 2px;
  }
}

.nav__submenu-category-heading {
  font-size: $text-4-size;
  line-height: $text-4-size;
  font-weight: $font-weight-extra-bold;
  padding-bottom: $space-1;
  padding-left: 3px;
}
