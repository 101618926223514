.tip p {
  overflow-wrap: break-word;
}

.tip-author {
  line-height: $text-5-line-height;
  font-size: $text-5-size;
}

.tip-thumb {
  border: 0px;
  width: 100%;

  &::after {
    content: " ";
    display: block;
    padding-bottom: 100%;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .tip-photo {
    width: inherit;
  }
}

.tip-user-avatar {
  position: relative;
  height: 32px;
  width: 32px;
  overflow: hidden;

  // Helps cover the img with the `:before` pseudo element when there is no avatar or if image fails to load
  picture {
    display: flex;
    width: 100%;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    font-size: 1px;
    color: #fff;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background-image: url('~@/images/avatar.png');
    }
  }
}

.tip-img {
  margin: 0px auto;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.tip-modal-wrapper {
  z-index: 999;
  width: 100%;
  height: 100%;
  border: none;
}

.tip-modal-content {
  width: 100%;
  height: 100%;
}

.tip-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(33, 41, 52, .85);
}

.close-button.button--clear {
  svg {
    height: 22px;
    width: 22px;
  }
  fill: $color-white-background;
  padding: $space-1;
}
