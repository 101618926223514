@import 'solid-helpers/variables';

$content-width: 75rem;
$solid-xs: 0;
$solid-sm: 40rem;
$solid-md: 52rem;
$solid-lg: 64rem;
$font-weight-bold: 600;
$font-weight-extra-bold: 800;

$text-1-size: 1.75rem;
$text-2-size: 1.375rem;
$text-3-size: 1.125rem;
$text-4-size: 1rem;
$text-5-size: .875rem;
$text-6-size: .75rem;

$text-1-line-height: 1.2;
$text-2-line-height: 1.2;
$text-3-line-height: 1.2;
$text-4-line-height: 1.3;
$text-5-line-height: 1.3;
$text-6-line-height: 1.3;

// *****************
// * Colors
// *****************

//
// *** FOUNDATIONAL color variables ***
//  - Are often different from BF for same basic colors (e.g. a different base red)
//  - Names are agnostic of use case
//  - In general, should AVOID trying to using below variables directly, and should
//    default to using them via an alias variable
//  - Should very rarely be added to or modified
//

// tasty base colors
// - "5" indicates the primary base color, shades/tints lighter (lower numbers) and
//   darker (higher numbers) can added as use cases arise
$color-outline-blue: #4d90fe;
$color-tasty-blue-5: #79dcf1;
$color-tasty-blue-6: #6fc7da;
$color-tasty-blue-7: #62a3b0;
$color-tasty-green-5: #3e721d;
$color-tasty-green-6: #5DA281;
$color-tasty-gray-2: #efefef;
$color-tasty-pink-2: #FF8997;
$color-tasty-pink-5: #e40754;
$color-tasty-pink-6: #d2034b;
$color-tasty-pink-7: #7d042e;
$color-tasty-pink-8: #390215;
$color-tasty-purple-5: #8247b1;
$color-tasty-red-5: #d0460b;
$color-tasty-red-6: #b72d00;
$color-tasty-red-7: #EE3322;
$color-tasty-teal-5: #0c8097;
$color-tasty-yellow-5: #fdec02;

// neutral utility colors
$color-gray-1: #f4f4f4;
$color-gray-2: #e6e6e6;
$color-gray-7: #757575;
$color-black: #222;
$color-white: #fff;

// non-BF/tasty brand colors
$color-facebook: #3b5998;
$color-pinterest: #e60019;
$color-twitter: #55acee;
$color-whatsapp: #43d854;

//
// *** ALIAS color variables ***
//  - When possible, are more semantically named based on use case.
//  - These should be used in the rest of the styling files when possible instead of
//    of the foundation tokens directly.
//  - These can be (and are expected to be) added to, either here (if shared in many spots)
//    or in other style files.
//  - Should reference another variable (often foundation) as opposed to a direct
//    hex color
//

// actions (general links and buttons)

$color-feed-item-label: $color-tasty-teal-5;
$color-footer-logo: $color-gray-2;
$color-footer-action: $color-tasty-pink-2;
$color-primary-action: $color-tasty-pink-5;
$color-primary-action-disclaimer: $color-tasty-pink-6;
$color-primary-action-hover: $color-tasty-pink-7;
$color-primary-action-pressed: $color-tasty-pink-8;
$color-primary-action-alternate: $color-tasty-blue-5;
$color-primary-action-alternate-hover: $color-tasty-blue-6;
$color-primary-action-alternate-pressed: $color-tasty-blue-7;

// error and success
$color-error: $color-tasty-red-5;
$color-newsletter-error: $color-tasty-pink-2;
$color-page-error: $color-tasty-red-6;
$color-required: $color-tasty-red-5;

// backgrounds
$color-cta-background: $color-tasty-gray-2;
$color-footer-background: $color-black;
$color-gray-background: $color-gray-1;
$color-highlight-background: $color-tasty-pink-5;
$color-nav-background: $color-tasty-blue-5;
$color-newsletter-background: $color-tasty-blue-5;
$color-white-background: $color-white;

// text
$color-text-cta: $color-tasty-pink-6;
$color-text-dark: $color-black;
$color-text-gray: $color-gray-7;
$color-text-white: $color-white;

//title
$color-title-red: $color-tasty-red-7;
$color-title-green: $color-tasty-green-6;

// borders
$color-focus-outline: $color-outline-blue;
$color-input-border: $color-gray-2;
$color-outline-gray: $color-gray-7;

// fill color
$color-black-fill: $color-black;
$color-gray-fill: $color-gray-7;
$color-red-fill: $color-tasty-red-6;

// social and sharing colors
//  For hover and pressed states, can programmatically set.  Darken 20% for hover and
//  35% for pressed
$color-facebook-share: $color-facebook;
$color-pinterest-share: $color-pinterest;
$color-twitter-share: $color-twitter;
$color-whatsapp-share: $color-whatsapp;
$color-sms-share: $color-tasty-green-5;
$color-email-share: $color-tasty-purple-5;
