//
// Print recipe page layout
//

@media print {
  .abFlipper,
  .Ad,
  .ad-wrapper,
  .compilation-header--content .share-bar,
  .feature-cta,
  .featured-in, // featured display by credits
  .featured-in-divider, // featured display by credits
  .footer,
  .newsletter,
  .non-video-recipe-image,
  .print-cta-modal,
  .print-cta-modal__overlay,
  .promo-unit,
  .content-wrap .related-content,
  .recipe-header--content .share-bar,
  .related-recipes,
  .save-in-app-banner, // branch cta button
  .save-in-app-bottom-sheet, // branch bottom banner
  .tips-header, // tips display at top of page
  .trending-recipes,
  .ugc-disclaimer,
  .video,
  // nav bar related
  .nav__primary-menu-item,
  .nav__primary-menu-search,
  .nav__submenu-toggle,
  .nav__primary-menu-mycart,
  .secondary-nav,
  .two-columns-recipe .recipe-content + div, // hide right column
  .community-recipes-container,
  .two-columns-recipe .tips-content,
  .two-columns-recipe .feed,
  .two-columns-recipe .shop-ingredients-wrapper,
  .two-columns-recipe .recent-recipes,
  .two-columns-recipe .show-more {
    display: none !important;
  }

  .nav-bar, .nav {
    position: initial !important; // prevent sticky nav during printing
  }
  .ingredients-prep {
    width: 100%;
    top: 0;
    height: 0;
  }

  .recipe-page .recipe-submit-cta {
    display: none;
  }

  .recipe-content {
    flex-direction: column;
  }

  .ingredients-prep {
    flex-direction: column;
    width: 100%;
    top: 0;
    height: 0;
    .nutrition-info .nutrition-details {
      display: block;
    }
    .nutrition-button {
      display: none;
    }
  }
  .nav {
    margin-bottom: 0;
    .nav__logo-container {
      width: 110px;
      height: 56px;
    }
  }
  .recipe-page {
    .recipe-content {
      min-height: 0 !important;
    }
    .recipe-header--content {
      margin-bottom: 0;
    }
  }
  .two-columns-recipe {
    width: 100%;
    .recipe-content,
    .preparation {
      width: 100% !important;
      max-width: 100% !important;
      padding-right: 0 !important;
    }
    .ingredients-prep {
      flex-direction: column;
      width: 100%;
    }
    .nutrition-info {
      display: block;
    }
  }
  .non-video-recipe {
    display: block !important;
    > div {
      padding-right: 0 !important;
    }
  }
}
