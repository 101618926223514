//
// Newsletter component
//
//

.newsletter--full {
  min-height: 306px;
  // stylelint-disable value-list-comma-space-after,indentation
  background-image: url("~@/images/newsletter/tomatos_xs.png"),
                    url("~@/images/newsletter/avocado_xs.png");
  // stylelint-enable value-list-comma-space-after,indentation
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  background-size: 129px auto, 98px auto;
  background-color: $color-newsletter-background;
  padding: $space-3 $space-2 $space-3 $space-2;
}

// IE11 and Opera mini supports only dpi unit. To get dpi, multiply the desired
// pixel ratio (1.5) by 96 (total pixels per inch) and append dpi
@media (-webkit-min-device-pixel-ratio:1.5), (min-resolution: 144dpi) {
  .newsletter--full {
    // stylelint-disable value-list-comma-space-after,indentation
    background-image: url("~@/images/newsletter/tomatos_xs@2x.png"),
                      url("~@/images/newsletter/avocado_xs@2x.png");
    // stylelint-enable value-list-comma-space-after,indentation
  }
}

.newsletter--inline {
  width: 100%;
}

.newsletter__form--full {
  max-width: $content-width;
  margin: 0 auto;
  text-align: center;
  padding-top: $space-05;
}

.newsletter__content--full {
  .newsletter__subtitle {
    padding: 0 $space-5;
  }
  .newsletter__input-label {
    display: block;
    padding-bottom: 2px;
  }
  .newsletter__input-label-text {
    font-weight: $font-weight-extra-bold;
  }
}

.newsletter__form--full {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsletter__header--full {
  padding: $space-1 $space-1 0 $space-1;
}

.newsletter__input-label {
  padding-bottom: $space-05;
  width: 100%;
}

.newsletter__input-wrapper--inline {
  display: flex;
  flex-wrap: wrap;
  max-width: 360px;
}

.newsletter__input-wrapper--full {
  text-align: left;
  .newsletter__input-disclaimer-link {
    color: $color-tasty-pink-7;
    text-decoration: underline;
    &:hover {
      color: darken($color-tasty-pink-7, 10%);
    }
  }
}

.newsletter__input {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.3rem;
  &.newsletter__input--error {
    border-color: $color-error;
  }
}

.newsletter__input--full {
  width: 100%;
  margin-bottom: $space-1;
  border: 1px solid $color-input-border;
  @include solid-breakpoint("md") {
    margin-bottom: 0;
  }
}

.newsletter__input--inline {
  border: 2px solid transparent;
  border-radius: 4px;
  flex-grow: 1;
  @include solid-breakpoint("md") {
    width: 259px;
  }
}

.newsletter__input-disclaimer {
  font-size: $text-6-size;
  line-height: $text-6-line-height;
  margin-top: $space-05;
  width: 263px;
}

.newsletter__input-disclaimer-link {
  color: $color-footer-action;
  &:hover {
    color: darken($color-footer-action, 10%);
  }
}

.newsletter__submit {
  margin-bottom: 0.3rem;
}

.newsletter__submit--full.button--tasty {
  width: 100%;
  margin-bottom: $space-1;
  margin-top: $space-1;
}

.newsletter__submit--inline {
  margin-left: 0.75rem;
}

.newsletter__errors {
  text-align: left;
  top: 100%;
  @include solid-breakpoint("md") {
    padding-top: $space-05;
  }
}

.newsletter__errors--full {
  color: $color-text-dark;
  @include solid-breakpoint("md") {
    padding-top: 0;
  }
}

.newsletter__errors--inline {
  color: $color-newsletter-error;
}

.newsletter__success-header, .newsletter__success-body {
  line-height: 1.2;
}

.newsletter__success--full {
  margin-top: $space-5;
  @include solid-breakpoint("md") {
    font-size: $text-1-size;
  }
  .newsletter__success-header {
    font-size: $text-2-size;
  }
  .newsletter__success-body {
    font-size: $text-4-size;
    margin: 0 $space-2;
  }
}

.newsletter__success--inline {
  .newsletter__success-header {
    font-size: $text-3-size;
  }
  .newsletter__success-body {
    font-size: $text-4-size;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
