.ingredients-header {
  @include solid-breakpoint("md") {
    margin: 0 auto;
    display: flex;
    max-width: 75rem;
    width: 100%;
    margin-top: $space-2;
  }
}

.ingredients-header__img-wrapper {
  position: relative;
  overflow: hidden;
  @include solid-breakpoint("md") {
    width: 55%;
    order: 2;
    margin-right: $space-2;
  }
}

.ingredients-header__img-wrapper::after {
  display: block;
  content: '';
  padding-bottom: 50%;

  @include solid-breakpoint("md") {
    padding-bottom: 0;
  }
}

.ingredients-header__img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform-origin: 50% 50%;
  transform: scale(1.8);
  @include solid-breakpoint("md") {
    transform: scale(1.0);
    position: relative;
    object-fit: initial;
    height: auto;
  }

}

.ingredients-header__title-wrapper {
  @include solid-breakpoint("md") {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
}

.ingredients-header__title-inner {
  left: 18px;
  bottom: 24px;
  display: flex;
  flex-direction: column;
  @media (min-width: $solid-md) {
    left: auto;
    order: 1;
  }
}

.ingredients-header__title {
  @media (min-width: $solid-md) {
    font-size: 64px;
  }
}

.ingredients-header__subtitle {
  @media (min-width: $solid-md) {
    font-size: $text-2-size;
  }
}

.grouped-list ul {
  columns: 2;
  column-gap: 70px;
  @media (min-width: $solid-sm) {
    columns: 3;
  }
  @media (min-width: $solid-md) {
    columns: 4;
  }
}

.grouped-list__item {
  display: inline-block;
  width: 100%;
  margin-bottom: 12px;
  a {
    color: $color-text-dark;
    &:hover, &:active {
      color: $color-primary-action;
    }
  }
}
