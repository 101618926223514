.home-page {
  color: $color-text-dark;
}
.home-container {
  width: 100%;

  .left-column {
    flex: 2;
  }

  .right-column {
    margin-left: 0;
    @include solid-breakpoint("md") {
      flex: 1;
      margin-left: 2rem;
    }
  }

  .assorted-carousels {
    margin-top: $space-5;

    @include solid-breakpoint("md") {
      margin-top: 4rem;
    }
  }

  .community-recipes-container {
    margin-top: $space-4;

    @include solid-breakpoint("md") {
      margin-top: $space-5;
      .button--tasty {
        display: none;
      }
    }
  }

  .stories-container {
    max-width: 400px;
    margin: 0 auto;
  }

  .assorted-carousel.assorted-carousel__right {
    @include solid-breakpoint("md") {
      padding-left: $space-1;
    }
  }

  .assorted-carousel.assorted-carousel__left {
    @include solid-breakpoint("md") {
      padding-right: $space-1;
    }
  }
}

.home__shopping-container {
  background: $color-gray-background;
  margin-bottom: -$space-4;
}
