@import '../Nav/Nav.scss';

.secondary-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: $color-gray-background;
  height: 42px;
  z-index: 996;

  @include solid-breakpoint("md") {
    margin-bottom: 0;
    height: 56px;
  }
}

.secondary-nav__link {
  font-size: $text-5-size;
  line-height: $text-5-line-height;
  font-weight: $font-weight-extra-bold;
  color: $color-text-dark;
  margin-left: 6px;
  padding-left: 2px;
  border: 1px solid transparent;
  @include solid-breakpoint("md") {
    margin-right: 30px;
    padding-right: 2px;
  }
  white-space: nowrap;
  &:hover {
    color: $color-primary-action;
  }
  &:focus-visible {
    outline-style: none;
    border-color: $color-outline-gray;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    margin: 0 6px 2px 0;
  }
}

.secondary-nav__mobile {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 0 0 0 16px;
  overflow-x: auto;
  /* Hide scrollbar for various browsers */
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @include solid-breakpoint("md") {
    display: none;
  }

  .secondary-nav__link {
    margin-right: 24px;
    padding-right: 2px;
  }
}

.secondary-nav__desktop {
  align-items: center;
  width: 100%;
  display: none;
  max-width: $content-width;
  margin: 0 auto;
  @include solid-breakpoint("md") {
    display: flex;
    padding: 0 0 0 16px;
  }

  @include solid-breakpoint("lg") {
    //Logo width + space between logo and Recipes button
    padding-left: $tasty-logo-width-lg + 36px;
  }
}
