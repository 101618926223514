/**  =====================================
  *  =====================================
  *  =====================================
  *  ONLY ADD EXTRA CLASSES TO THIS FILE
  *   IF CAREFULLY CONSIDERED
  *  =====================================
  *  =====================================
  *  =====================================
  */

/**
  * This file contains the bare minimum additional Solid (a BuzzFeed style library -
  *   https://solid.buzzfeed.com/) classes for ads for tasty use cases (primarily awareness)
  *   If other classes aren't supported on an individual ad unit, update the ad code itself
  *   to not rely on a Solid class.
  *
  * While tasty still does use solid, it is likely to move away from, so keep these separate
  *   They can be removed when adlib is updated to not use solid.
  */

.flex {
  display: flex;
}
.xs-block {
  display: block;
}
.xs-hide {
  display: none;
}

.xs-absolute {
  position: absolute;
}
.xs-fixed {
  position: fixed;
}
.xs-relative {
  position: relative;
}
.xs-r1{
  right: $space-1;
}
.xs-t1{
  top: $space-1;
}

.xs-flex-grow-1 {
  flex-grow: 1;
}
.xs-flex-shrink-0 {
  flex-shrink: 0;
}
.xs-flex-align-center {
  align-items: center;
}

.xs-text-center {
  text-align: center;
}

.xs-mr1 {
  margin-right: $space-1;
}

@include solid-breakpoint("sm") {
  .sm-hide {
    display: none;
  }
}

@include solid-breakpoint("md") {
  .md-flex {
    display: flex;
  }

  .md-text-left {
    text-align: left;
  }
}
