.inline-share-bar {
  min-height: 1.25rem;
  position: relative;

  .share-button {
    position: absolute;
    top: -12px;
    right: 0;
  }
}
