.ingredients-list {
  ul {
    list-style: none;
    padding-left: 0;
  }
  .ingredients-list__header {
    display: flex;
    justify-content: space-between;

    h2 {
      @include solid-breakpoint("md") {
        font-size: 32px;
      }
    }
  }

  .ingredients-list__servings {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .ingredients-list__content {
    margin: $space-1 0;
    transition: 0.2s filter linear;
    will-change: filter;
    border-collapse: collapse;
  }

  .ingredients-list__ingredient-info-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $space-1 0;
  }

  .ingredients-list__swap-ingredients {
    margin: 0 -20px;
    width: calc(100% + 40px);
    max-width: unset;

    .ingredients-list__ingredient-container {
      div:first-of-type {
        padding-left: 36px;
        width: 78px;
      }

      div:last-of-type {
        padding-right: 36px;
      }
    }

    li {
        border-bottom: 1px solid #e5e5e5;
    }
  }

  .ingredients-list__ingredient--hovered {
    cursor: pointer;

    &:hover {
      background-color: $color-gray-1;
    }
  }

  .ingredients-list__ingredient-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    &:nth-of-type(2n) {
      border-bottom: 1px solid #e5e5e5;
    }

    .ingredients-list__ingredient-img,
    .ingredients-list__ingredient-body,
    .ingredients-list__ingredient-price {
      will-change: opacity;
      transition: opacity 0.2s ease;
    }

    .ingredients-list__ingredient-img {
      img {
        width: 42px;
        min-width: 42px;
        vertical-align: middle;
      }
    }

    .ingredients-list__disclaimer-text {
      font-size: $text-6;
      font-style: italic;
      color: $color-text-gray;
    }

    .ingredients-list__ingredient-name {
      padding: 1.5rem $space-2;
      .button--tasty {
        padding: 0;
        display: flex;
      }
    }

    .ingredients-list__buttons-container {
      padding: 0 $space-2 $space-2;
      margin-left: 2.6rem;
    }

    .ingredients-list__ingredient-quantity {
      align-self: flex-start;
      margin-right: 16px;
    }

    .ingredients-list__not-available-text {
      white-space: nowrap;
      font-weight: 800;
      font-size: 12px;
      line-height: 20px;
      color: $color-text-gray;
    }

    .ingredients-list__ingredient-price {
      margin-left: auto;
    }

    .ingredients-list__ingredient-add {
      width: 20px;
      height: 20px;
      border-radius: 50%;

      overflow: auto;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 0;
      overflow: visible;
      padding: 0;

      svg {
        fill: #fff;
        width: 100%;
        height: 100%;
      }
    }
  }

  .ingredients-list__ingredient--editing {
    .ingredients-list__ingredient-img,
    .ingredients-list__ingredient-body,
    .ingredients-list__ingredient-price {
      opacity: 0.2;
    }
  }

  .ingredients-list__disclaimer {
    color: $color-text-gray;
  }

  .ingredients-list__checkout {
    display: inline-flex;
    padding-left: $space-1;
    padding-right: $space-1;

    @include solid-breakpoint("md") {
      width: auto !important;
      padding-left: $space-4;
      padding-right: $space-4;
    }
  }

  .ingredients-list__walmart-link {
    @include solid-breakpoint("md") {
      span {
        margin-left: auto;
      }
    }
  }
  .disabled {
      pointer-events: none !important;
  }
}

@media (max-width: 40rem) {
  .ingredients-list__sticky-container {
    position: sticky;
    bottom: 0;
    background-color: $color-white;
  }
}

.ingredients-list--with-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20rem;

  img {
    width: 104px;
    height: 104px;
  }
}

.ingredients-list--loading {
  .ingredients-list__content {
    filter: blur(5px);
  }
}
