@mixin shop-image-width($percent) {
  width: $percent;
  min-width: $percent;
  // IE11
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    max-width: $percent;
    flex-shrink: 0;
  }
}

.shop {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include solid-breakpoint("md") {
    flex-direction: row;
  }
}

.shop__category {
  .shop__category-item--cookbooks {
    flex-grow: 1;
    width: 33.333%;
  }
  .shop__category-item-image-wrapper {
    position: relative;
  }
  .shop__category-item-image-wrapper--cookbooks {
    @include aspect-ratio(7, 9);
  }
  .shop__category-item-image-wrapper--cookware {
    flex-shrink: 0;
  }
  &:nth-child(2) {
    flex: 1;
  }
}

.shop__category--nav {
  .shop__category-content {
    @include solid-breakpoint("md") {
      height: calc(100% - 92px);
    }
    .shop__category-item-image-wrapper--cookware {
      @include shop-image-width(47%);
      padding-top: 45%;
      flex-shrink: 0;
      @include solid-breakpoint("lg") {
        @include shop-image-width(45%);
      }
    }
  }
  @include solid-breakpoint("md") {
    &:first-child {
      width: 60%;
      min-width: 60%;
      padding-right: 6rem;
    }
  }
}

.shop__category--body {
  display: flex;
  flex-direction: column;
  &:first-child {
    margin-bottom: $space-2;
  }
  @include solid-breakpoint("md") {
    &:first-child {
      min-width: 54%;
      width: 54%;
      margin-right: 3.5rem;
      margin-bottom: 0;
    }
  }
  @include solid-breakpoint("lg") {
    &:first-child {
      min-width: 58%;
      width: 58%;
      margin-right: 3.5rem;
    }
  }
  .shop__category-content {
    @include solid-breakpoint("md") {
      height: calc(100% - 102px);
    }
    @include solid-breakpoint("lg") {
      height: calc(100% - 84px);
    }
    .shop__category-item-image-wrapper--cookware {
      @include shop-image-width(48%);
      padding-top: 46%;
      flex-shrink: 0;
      @include solid-breakpoint("md") {
        @include shop-image-width(53%);
      }
      @include solid-breakpoint("lg") {
        @include shop-image-width(62.5%);
      }
    }
  }
}

.shop__category-heading {
  display: flex;
  align-items: center;
  font-size: $text-4-size;
  line-height: $text-4-line-height;
  padding-bottom: 0.75rem;
}

.shop__category-item-image {
  position: absolute;
  top: 0;
}

.shop__shop-all-link {
  font-weight: $font-weight-extra-bold;
  border: 1px solid transparent;
  display: none;
  @include solid-breakpoint("md") {
    display: block;
  }
}

.shop__shop-all-button.button--tasty {
  display: block;
  font-weight: $font-weight-extra-bold;
  margin-bottom: $space-4;
  background: transparent !important;
  @include solid-breakpoint("md") {
    display: none;
  }
}

.shop__shop-all-link {
  text-decoration: underline;
}

.shop__category-content {
  display: flex;
  padding-bottom: 0;
  @include solid-breakpoint("md") {
    padding-bottom: $space-2;
  }
}

.shop__category-item {
  &:not(:last-child) {
    margin-right: 1.4rem;
  }
  &:hover {
    .shop__category-item-title {
      color: $color-primary-action;
    }
  }
  a {
    .shop__category-item-title, .shop__category-item-link {
      outline: none;
      border: 1px solid transparent;
    }
    &:focus {
        outline: none;
        border: 1px solid transparent;
      .shop__category-item-link {
        outline: none;
        border: 1px solid transparent;
      }
      .shop__category-item-title {
        outline: none;
        border: 1px solid $color-outline-gray;
      }
    }
  }
}

.shop__category-item-title {
  color: $color-text-dark;
}

.shop__category-item--cookware {
  display: flex;
  flex-grow: 1;
  margin-bottom: $space-2;
  @include solid-breakpoint("md") {
    margin-bottom: 0;
  }
}
.shop__category-item-link--cookware {
  display: flex;
  flex: 1;
  margin-bottom: $space-1;
  .shop__category-item-title {
    margin-left: 0.75rem;
    margin-top: 0;
  
  }
  @include solid-breakpoint("md") {
    margin-bottom: 0;
  }
}

.shop__category-item--cookbooks {
  padding-bottom: $space-2;
  // IE11
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    max-width: 32%;
    flex-shrink: 0;
  }
}
.shop__category-item-link--cookbooks {
  .shop__category-item-title {
    margin-top: 0.25rem;
  }
}
