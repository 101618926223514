@use "sass:math";
.feature {
  .feed-item {
    @include solid-breakpoint("sm") {
      width: calc-block-width(percentage(math.div(1,4)));
    }
  }
}

.feature__brand {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px 0 20px;

  @include solid-breakpoint("sm") {
    margin-bottom: $space-3;
  }
}

.feature__brand-logo {
  height: 24px;
  border-radius: 3px;
  margin-right: .75rem;

  @include solid-breakpoint("sm") {
    height: 40px;
  }
}

.feature__img {
  position: relative;
  background-color: $color-gray-background;
  padding-top: 48%;

  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.feature__dot-gap {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}

@include solid-breakpoint("sm") {
  .feature__text {
    max-width: calc(#{$content-width} / 2);

    h1 {
      font-size: 3.750rem !important;
    }
  }
}

.feature__sections-header:before {
  content: '';
  display: block;
  position: relative;
  width: 0;
  height: 16px;
  margin-top: -16px;
}

.feature__sections-content {
  margin: -$space-1;
  padding-left: 0;
}

@include solid-breakpoint("md") {
  .feature {
    padding-top: 1rem;
  }

  .feature__header {
    padding-top: 59.832%;
  }

  .feature__img {
    display: flex;
    width: 50%;
    padding-top: 0;
  }

  .feature__section--ad {
    margin-bottom: 2.2rem;
    margin-top: 36px;
  }

  .feature__sections-header:before {
    height: 100px;
    margin-top: -100px;
  }
}

@media (min-width: 74.38rem) {
  .feature__header {
    padding-top: 0;
    height: 44.5rem;
  }
}

.feature__sections-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

// I have to use the element qualifier to be *extra* specific
// due to solid + media query specificity
ul.feature__sections-wrapper {
  line-height: 1.4 !important;
}
