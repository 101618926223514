.instruction-item {
  box-sizing: border-box;
  min-height: 128px;
  border: 2px solid $fill-gray-lighter;
  border-radius: 4px;

  .icon {
    width: 50%;
    height: 50%;
    object-fit: contain;
  }

  .instruction-item__info {
    padding: 20px 26px 18px 0;

    @include solid-breakpoint("lg") {
      padding: 24px 42px 22px 0;
    }

    em {
      margin-top: auto;
    }
  }
}