.share-button {
  & > svg {
    width: $space-2;
    height: $space-2;
    top: 0.125rem;
    position: relative;
  }
}

.share-button--branded:not(.share-button--outline) {
  @extend .button--tasty;
  &.share-button--email {
    @include button-style($color-email-share, $color-text-white);
  }
  &.share-button--pinterest {
    @include button-style($color-pinterest-share, $color-text-white);
  }
  &.share-button--facebook {
    @include button-style($color-facebook-share, $color-text-white);
  }
  &.share-button--sms {
    @include button-style($color-sms-share, $color-text-white);
  }
  &.share-button--twitter {
    @include button-style($color-twitter-share, $color-text-white);
  }
  &.share-button--whatsapp {
    @include button-style($color-whatsapp-share, $color-text-white);
  }
}

.share-button--pink {
  background-color: transparent;
  border-color: transparent;
  border: 1px solid transparent;
  padding: 0px;
  text-align: center;
  &:active {
    outline: 0;
  }
  & > svg {
    fill: $color-primary-action;
    width: 1.375rem;
    height: 1.375rem;
    &:hover {
      fill: $color-primary-action-hover;
    }
  }
}

.share-button--round:not(.share-button--print):not(.share-button--tips) {
  border-radius: 50%;
}

.share-button--wide {
  width: 100%;
  display: block;
  svg {
    margin-right: $space-1;
  }
}

.share-button--outline {
  background: $color-white-background;
  border-radius: $border-radius;
  padding: 0.3125rem 0.625rem;
  font-size: $text-5-size;
  line-height: $text-5-line-height;
  display: flex;
  font-weight: $bold;
  & > svg {
    top: 0;
    margin-right: $space-1;
  }

  &.share-button--pinterest {
    border: 1px solid $color-pinterest-share;
    color: $color-pinterest-share;
    & > svg {
      fill: $color-pinterest-share;
    }
    &:hover {
      color: $color-text-white;
      background: $color-pinterest-share;
    }
    &:hover > svg use {
      fill: $color-text-white;
    }
  }
}

.share-button--monochrome {
  line-height: 1;
  margin: 0 1.5rem 0 0;
  &:not(.share-button--tips) > svg {
    width: 2rem;
    height: 2rem;
    top: 0;
  }
  &.share-button--print {
    background: none;
    padding: 0;
    border: none;
  }
}

.share-button--black:not(.share-button--tips) {
  & > svg {
    fill: #000;
  }
  &.share-button--email {
    & > svg {
      fill: none;
      stroke: #000;
    }
  }
  &:hover {
    color: #444;
    & > svg {
      fill: #444;
    }
    &.share-button--email {
      & > svg {
        fill: none;
        stroke: #444;
      }
    }
  }
}

.share-button--tips {
  @extend .button--tasty;
  font-size: $text-5-size;
  font-weight:  $xbold;
  display: flex;
  align-items: center;
  & > svg {
    display: inline-block;
    vertical-align: top;
    top: 0;
    width: 22px;
    height: 22px;
    margin-right: $space-1;
    stroke: $color-text-white;
    fill: none;
  }
}
