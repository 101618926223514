//
// Utility Classes
// --------------------------------------
//

/*
If there is a long word (for example, in the tips body), the layout will break. This is
because on a a "flexible" element, the default is to take the width if its
narrowest child (min-width: auto), which in this case are for the loooooong words.
By specifying min-width to the minimal amount (0px) on the element that is
flexible, the break-word property can behave as expected.
*/
.min-w0 {
  min-width: 0;
}

@function calc-block-width($block-grid-width, $block-spacing: 1rem) {
  @return calc(#{$block-grid-width} - #{$block-spacing});
}
