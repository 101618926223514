@import "../../FeedItem/FeedItem.scss";

@include solid-breakpoint("sm") {
  .ingredients__section > ul {
    -webkit-columns: 2;
    columns: 2;
    list-style-position: inside;
    list-style-type: square;
    /* Lists of 4 or less should not split into columns */
    @for $i from 1 through 4 {
      li:first-child:nth-last-child(#{$i}),
      li:first-child:nth-last-child(#{$i}) ~ li {
        column-span: all;
        list-style-type: none;
      }
    }
  }
}
@include solid-breakpoint("md") {
  .ingredients__section > ul {
    -webkit-columns: 1;
    columns: 1;
    list-style-type: none;
    li {
      font-feature-settings: normal;
    }
  }
}
.linked-recipe {
  @extend .feed-item;
  display: flex;
  width: initial;
  margin: 0;
  &:hover .linked-recipe__title {
    color: $color-primary-action;
  }
}
.linked-recipe__img-wrapper {
  @extend .feed-item__img-wrapper;
}
.linked-recipe__img {
  @extend .feed-item__img;
}
.linked-recipe__title {
  color: #222;
}
