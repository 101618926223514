.tag-filters {
  display: flex;
  flex-wrap: wrap;
  margin: 0 $space-2;
}
.tag-filters__category {
  width: 100%;
  @include solid-breakpoint("md") {
    width: 33.3333%;
  }
  @include solid-breakpoint("lg") {
    width: 16.6667%;
  }
}
.tag-filters__category-header {
  color: $color-text-dark;
  text-transform: capitalize;
  font-size: $text-4-size;
  line-height: $text-4-line-height;
  margin: $space-1 0;
  font-weight: $font-weight-extra-bold;
}
.tag-filters__filter-expand,
.tag-filters__filter {
  font-weight: $font-weight-extra-bold;
  background-color: $color-white-background;
  color: $color-primary-action;
}
.tag-filters_filters-group {
  display: flex;
  margin-bottom: $space-2;
  flex-wrap: wrap;
  @include solid-breakpoint("md") {
    flex-direction: column;
  }
}
.tag-filters__filter {
  font-size: $text-5-size;
  line-height: $text-4-line-height;
  text-transform: capitalize;
  text-align: left;
  
  width: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem .75rem;
  margin: .25rem .125rem;

  border: 2px solid $color-primary-action;
  border-radius: 2rem;

  &:hover, &.selected {
    transition: color .15s ease;
  }
  &:hover:not(.selected) {
    border-color: $color-primary-action-hover;
    color: $color-primary-action-hover;
  }
  &:hover.selected {
    border-color: $color-primary-action-hover;
    background-color: $color-primary-action-hover;
  }
  &.selected {
    background-color: $color-primary-action;
    border-color: $color-primary-action;
    color: $color-text-white;
  }
}
.tag-filters__no-matching {
  font-weight: $bold;
  color: $color-text-gray;
  font-size: $text-5-size;
  line-height: $text-5-line-height;
}

.tag-filters__filter-expand{
  padding: $space-1;
  border: none;
  font-size: $text-5-size;
  line-height: $text-5-line-height;
  width: fit-content;
}
