.recent-page {
  &__container {
    max-width: 75rem;
    margin: 0 auto;
    padding: 0 .5rem;

    @include solid-breakpoint("md") {
      padding: 0 1rem;
    }

    .feed__container {
      margin: 0;
    }
  }

  &__title-wrapper {
    margin-bottom: 1rem;

    @include solid-breakpoint("md") {
      margin-bottom: 3rem;
    }
  }

  &__title {
    text-align: center;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 48px;
    margin-bottom: .5rem;

    @include solid-breakpoint("md") {
      margin-bottom: 1rem;
      font-size: 62px;
      line-height: 76px;
    }
  }

  &__disclaimer {
    display: flex;
    align-items: center;
    justify-content: center;

    @include solid-breakpoint("md") {
    }

    &__image-wrapper {
      width: 14px;
      height: 14px;
      position: relative;

      @include solid-breakpoint("md") {
        width: 24px;
        height: 24px;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__name {
      margin-left: .5rem;
      font-size: 14px;
      line-height: 14px;

      @include solid-breakpoint("md") {
        font-size: 22px;
      }

      a {
        text-decoration: underline;
        font-weight: bold;
        color: #222;
      }
    }
  }

  &__hero-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__hero-image {
    width: 100%;
    height: 170px;
    vertical-align: middle;
    object-fit: cover;

    @include solid-breakpoint("md") {
      height: auto;
    }
  }

  &__hero-image-ratio-wrapper {
    padding-top: 20%;
    position: relative;
    width: 100%;
    height: 170px;
    margin-bottom: 2rem;

    @include solid-breakpoint("md") {
      height: auto;
      margin-bottom: 5rem;
    }
  }
}
