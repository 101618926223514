.ingredient-breadcrumbs {
  .ingredient-breadcrumbs__item {
    display: inline-block;
  }
  .ingredient-breadcrumbs__link {
    font-size: $text-3-size;
    line-height: $text-3-line-height;
    font-weight: $font-weight-extra-bold;
    color: $color-text-dark;
    &:hover {
      color: $color-primary-action;
    }
  }

  .ingredient-breadcrumbs__divider {
    width: 12px;
    height: 12px;
    margin: 0 $space-1;
  }
}