.recipe-time-container {
  @include solid-breakpoint("md") {
    width: 320px;
  }
}

.recipe-time {
  &:not(:last-child) {
    border-right: 2px solid $color-gray-1;
  }
}