@use "sass:math";
@import 'solid-helpers/mixins';

@mixin aspect-ratio($width, $height) {
  padding-top: (math.div($height, $width)) * 100%;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin input-reset {
  border: none;
  outline: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
}

@mixin button-reset {
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;

  &:active {
    outline: 0 !important;
  }
}
