$faq-modal-close-button-size: 26px;
$faq-modal-close-button-size-md: 32px;

.react-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
  overflow-y: scroll;
}

.faq-modal {
  outline: none;
  width: 100%;

  @include solid-breakpoint("md") {
    max-width: 666px;
    margin: 100px auto;
  }

  .faq-modal__button-close {
    svg {
      height: $faq-modal-close-button-size;
      width: $faq-modal-close-button-size;

      @include solid-breakpoint("md") {
        height: $faq-modal-close-button-size-md;
        width: $faq-modal-close-button-size-md;
      }
    }

    fill: $fill-white;
  }

  .faq-modal__header {
    padding: 60px 42px 0;

    @include solid-breakpoint("lg") {
      padding: 70px 110px 0;
    }

    .faq-modal__title {
      text-align: center;
      white-space: pre-wrap;
    }

    .faq-modal__walmart-logo {
      img {
        height: 20px;
      }
    }
  }

  .faq-modal__footer {
    min-height: 110px;

    .walmart-grocery-help-link {
      svg {
        width: 11px;
        height: 9px;
        margin-left: 3px;
      }
    }
  }
}

.instruction-item-margin {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}