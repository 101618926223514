.featured-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: 50% 50%;
  transition: 0.2s ease-in-out;
  display: block;

  img {
    height: 100%;
    width: 100%;
  }
  picture {
    display: flex;
    height: 100%;
  }

  &:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}
}

.featured-img-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  padding-top: 100%;
  border-radius: 4px;
  padding-top: 0;
}

.featured-img-aspect-ratio-wrapper {
  background: $color-gray-background;
}