$banner-height: 3.5rem;
$bottom-sheet-height: 219px;

.save-in-app-banner {
  z-index: 400;
  height: $banner-height;
  width: 100%;
  position: fixed;
  bottom: 0;
  transition: bottom 0.2s ease;
  background-image: linear-gradient(-180deg, rgba(255,255,255,.00) 0%, rgba(255,255,255,.80) 100%);
  display: flex;
  justify-content: center;
  align-items: center;

  &.save-in-app-banner--collapsed {
    bottom: -$banner-height;
    height: 0;
    border-top: 0;
    padding: 0px;
  }

  .save-in-app-banner__button {
    &.button--tasty:active {
      background-color: $color-primary-action-hover;
      border-color: $color-primary-action-hover;
    }
    border-radius: 30px;
    padding: 6px 24px;
  }
}

#branch-bottom-sheet {
  z-index: 1000;
  width: 100%;
  position: sticky;
  bottom: 0;
}

.save-in-app-bottom-sheet {
  height: $bottom-sheet-height;
  transition: margin-bottom 0.2s ease;
  margin-bottom: 0;
  background-color: white;
  padding: 20px;
  border-top: 2px solid #F4F4F4;

  .save-in-app-bottom-sheet__content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &.save-in-app-bottom-sheet--collapsed {
    margin-bottom: -$bottom-sheet-height;
    height: 0;
    padding: 0;
  }

  p {
    line-height: 18px;
  }

  .tasty-app-logo {
    height: 63px;
    width: 63px;
  }

  .button--tasty {
    width: 100%;
  }

  .button--tasty-secondary {
    border: None;
  }
}

@media (min-width: $solid-sm) {
  .save-in-app-bottom-sheet__content-wrapper {
    max-width: 350px;
    margin: 0 auto;
  }
}
