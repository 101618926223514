.brand-page {
  &__container {
    max-width: 75rem;
    margin: 0 auto;
    padding: 0 0.5rem;

    @include solid-breakpoint("md") {
      padding: 0 1rem;
      li {
        margin-bottom: 2rem;
      }
    }

    .feed__container {
      margin: 0;
    }
  }

  &__title-wrapper {
    margin-bottom: 2rem;

    @include solid-breakpoint("md") {
      margin-bottom: 5rem;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 0.2rem;

    @include solid-breakpoint("md") {
      margin-bottom: 1rem;
      font-size: 62px;
      line-height: 76px;
    }
  }

  &__logo {
    width: 24px;
    height: 24px;
    align-self: center;

    border: 1px solid #8A8A8A;
    border-radius: 4px;

    @include solid-breakpoint("md") {
      width: 40px;
      height: 40px;
    }
  }

  &__brand-name {
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;

    @include solid-breakpoint("md") {
      font-size: 56px;
      line-height: 100%;
    }
  }

  &__recipes-count {
    color: #7c7c7c;
    font-weight: 500;
    align-self: start;

    @include solid-breakpoint("md") {
      align-self: flex-end;
      margin-left: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }

  &__description {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    align-self: flex-start;

    @include solid-breakpoint("md") {
      font-weight: 400;
      font-size: 24px;
      line-height: 120%;
    }
  }
}
