//Override text-decoration
abbr[title] {
    text-decoration: none;
    color: $color-required;
}

.ugc-form {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
  padding: 0 $space-2;
  width: 100%;

  //clear default styles
  input, textarea, button, a {
    outline-style: none;
    box-shadow: none;
  }
  input:focus, textarea:focus, button:focus, a:focus {
    outline: none;
    border: 1px solid $color-focus-outline;
    -webkit-box-shadow: 0 0 5px $color-focus-outline;
    box-shadow: 0 0 5px $color-focus-outline,
  }
}

.ugc-form__page--error {
  margin-top: 4.5rem;
  @include solid-breakpoint("sm") {
    margin-top: 3.5rem;
  }
  @include solid-breakpoint("md") {
    margin-top: 2.5rem;
  }
}

.ugc-form__checkbox:focus + label::before {
  outline: none;
  border: 1px solid $color-focus-outline;
  -webkit-box-shadow: 0 0 5px $color-focus-outline;
  box-shadow: 0 0 5px $color-focus-outline;
}

.ugc-form__heading {
  margin-top: $space-1;
  padding-right: 20px;
}

.ugc-form__title {
  font-size: $text-1; //28px
  @include solid-breakpoint("md") {
    font-size: 56px;
  }
}

.ugc-form__description {
  padding-top: $space-2;
  @include solid-breakpoint("md") {
    padding-right: 5rem;
  }

  & p {
    padding-bottom: $space-2;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.ugc-form__section {
  padding: $space-2 0;
  width: 100%;
  @include solid-breakpoint("lg") {
   width: 55%;
    &.ugc-form__heading {
      width: 70%;
    }
  }
}

.ugc-form__input {
  border: 1px solid $color-input-border;
  padding: $space-1;
  width: 100%;
}

.ugc-form__input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.ugc-form__fieldset--error {
  color: $color-error;
  input, textarea {
    border: 1px solid $color-error;
  }
}

.ugc-form__input--error {
  color: $color-error;
  font-size: $text-5;
  line-height: 17px;
  padding-top: $space-1;
}

.ugc-form__input--email {
  margin-bottom: $space-1;
}

.ugc-form__input--email--error {
  padding-top: 0;
  padding-bottom: $space-05;
}

.ugc-form__input--num-servings {
  width: 152px;
}

.ugc-form__label {
  display: inline-block;
  margin-top: $space-4;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: $text-4;
  width: 100%;
}
.ugc-form__label--subheader {
  margin-top: $space-2;
}

.ugc-form__section--recipe-credit {
  margin-bottom: 2.5rem;
}

.ugc-form__photo-credit-container {
  display: block;
  font-size: $text-5;
  @include solid-breakpoint("sm") {
    display: flex;
    justify-content: space-between;
  }
}

.ugc-form__description,
.ugc-form__photo-credit-container,
.ugc-form__checkbox-text,
.ugc-form__upload-list {
  svg {
    margin-left: $space-05;
  }
}
.ugc-form__checkbox-text {
  svg {
    margin-right: $space-05;
  }
}

.ugc-form__photo-credit {
  font-style: italic;
  color: $color-text-gray;
}

.ugc-form__helper-text {
  color: $color-text-gray;
  font-size: $text-5;
  line-height: 17px;
}

.ugc-form__button--submit {
  width: 100%;
  @include solid-breakpoint("sm") {
    width: 246px;
  }
}

.ugc-form__name-container {
    display: block;
  @include solid-breakpoint("sm") {
    display: flex;
    justify-content: space-between;
  }
}

.ugc-form__name-item {
  @include solid-breakpoint("sm") {
    width: 48%;
  }
}

////Placeholder to appears on multi/new lines

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ugc-form__fieldset--textarea {
  .ugc-form__input--error {
    padding-top: 0;
    padding-bottom: 4px;
  }
}

.ugc-form__textarea {
  min-height: 80px;
  resize: vertical;
  background-color: transparent;
  &.hide-placeholder {
    background-color: $color-white-background;
  }
}

.ugc-form__placeholder-container {
  z-index: 0;
  position: relative;
}

.ugc-form__placeholder {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px;
  color: $color-text-gray;
}

::-webkit-input-placeholder {
  color: $color-text-gray;
}
:-moz-placeholder {
  color: $color-text-gray;
}
::-moz-placeholder {
  color: $color-text-gray;
}
:-ms-input-placeholder {
  color: $color-text-gray;
}

.ugc-form__page-error-container {
  position: fixed;
  top: 70px;
  background-color: $color-white-background;
  width: calc(100% - 1rem);
  z-index: 2;
}

.ugc-form__page-error-contents {
  background-color: lighten($color-error, 50%);
  color: $color-page-error;
  font-weight: 600;
  margin-right: $space-2;
  padding: 20px 18px 20px 8px;

  @include solid-breakpoint('md') {
    margin-top: 0.75rem;
  }
  @include solid-breakpoint('lg') {
    width: 80%;
    margin-right: 0;
  }
}

.ugc-form__svg-x--wrapper {
  width: 23px;
  min-width: 23px;
  height: 23px;
  margin: 0 $space-2 0 $space-2;
}

.svg-x {
  fill: $color-red-fill;
  border-radius: 50%;
}

// IE11
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .ugc-form {
    margin-top: 0px !important;
    padding-top: 5.5rem;
  }
  .ugc-form.ugc-form__page--error {
    padding-top: 9rem;
    @include solid-breakpoint('sm') {
      margin-top: 8rem;
    }
  }
}
