.feed__items {
  font-size: 0;
  padding: 0;
  margin-bottom: $space-1;
}

.feed__scrollable-mobile {
  @media (max-width: $solid-md) {
    .feed__heading {
      margin-left: 0;
    }

    .feed__items {
      padding: 0;
      margin: 0 -1rem;
      display: flex;
      flex-wrap: nowrap;
      overflow: auto;
      /* Hide scrollbar for IE and Edge */
      -ms-overflow-style: none;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .feed__items::-webkit-scrollbar {
      display: none;
    }
  }
}

.feed__6-col {
  .feed__items {
    display: flex;
  }
}

.see-all-btn {
  display: block;
  width: 100%;

  h4 {
    color: $color-primary-action;
  }

  @include solid-breakpoint("md") {
    display: none !important;
  }
}
