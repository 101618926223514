.share-button {
  margin-right: $space-05;
}
.share-button--monochrome {
  margin-right: 12px;
}
.share-button--print:not(.share-button--monochrome) {
  margin-left: $space-05;
  @include solid-breakpoint("md") {
    margin-left: $space-1;
  }
}
.share-button--sms {
  @include solid-breakpoint("md") {
    display: none;
  }
}
.share-bar {
  li {
    display: flex;
    align-items: center;
  }
}
.share-bar--recipe {
  width: 100%;
  .share-button--monochrome {
    margin: 0 1.2rem 0 0;
  }
}
