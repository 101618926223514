.feed__heading {
  display: inline-block;
  font-size: $text-5-size;
  font-weight: $font-weight-extra-bold;
  color: $text-gray;
  text-transform: none;
  font-size: $text-2-size;
  line-height: 1.5;
  margin-left: $space-1;

  &--with-link {
    &:hover {
      color: $color-tasty-pink-5;
    }

    .arrow {
      display: inline;
      padding-top: 0.2rem;
    }

    @include solid-breakpoint("md") {
      &:hover {
        a {
          color: $color-primary-action;
        }

        svg {
          fill: $color-primary-action;
        }
      }
    }
  }
}
