@media (min-width: 52rem) {
  .anchor {
    position: absolute;
    top: -70px;
  }
}

.pagination .pagination__button {
  height: 32px;
  svg {
    fill: $color-primary-action;
  }
  &:hover:not(.pagination__button--disabled):not(:disabled) {
    svg {
      fill: $color-primary-action-hover;
    }
  }
  &:focus {
    outline: auto;
  }
}

.tips {
  column-count: 2;
  -webkit-column-count: 2;
  column-gap: $space-3;
  -webkit-column-gap: $space-3;
  position: relative;
}

.tips-content h3 {
  font-size: $text-3-size;
  font-weight: $xbold;
}

.tips__loading {
  opacity: 0.2;
}

@media (max-width: $solid-md) {
  .tips {
    column-count: 1;
    -webkit-column-count: 1;
  }
}

.tips__loading-donut-wrapper {
  position: absolute;
  z-index: 1;
  top: 10%;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  height: 100px;
  width: 100px;

  @include solid-breakpoint("md") {
    top: 30%;
  }
}
