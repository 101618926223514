.print-cta-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-y: scroll;
}

.print-cta-modal {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  outline: none;
  width: 375px;
  max-width: 95%;
  margin: 0 auto;
  padding: 30px 22px 20px 22px;

  .button--tasty {
    width: 100%;
  }

  .button--tasty-secondary {
    border: None;
  }
}

.print-cta-modal__app_logo {
  width: 70px;
}