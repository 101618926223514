.bf-cnx-override-big-button
  .cnx-main-container
  .cnx-player-wrapper
  .cnx-content-wrapper
  .cnx-video-container
  .cnx-ui
  .cnx-ui-content
  .cnx-ui-btn
  .cnx-button-big-play {
  display: inline-block;
  z-index: 100;
  border-radius: 50%;
  border: none;
  background: $color-tasty-pink-5;
  height: 84px;
  width: 84px;
  top: calc(50% - 42px);
  left: calc(50% - 42px);
  .cnx-btn-container
    .cnx-play-icon {
      margin: 25px;
    }
  }

.video-1-1, .video-4-5, .video-16-9 {
  position: relative;
  .cnx-main-container {
    position: absolute;
    top: 0;
    left: 0;
  }
}
