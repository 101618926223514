@use "sass:math";
.credits-wrapper {
  &:hover {
    cursor: pointer;
    .presented {
      color: black;
    }
  }
}

.brand-image-wrapper {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 4px;
  @include solid-breakpoint("md") {
    width: 3.5rem;
    height: 3.5rem;
  }
}

.description {
  white-space: pre-wrap;
  a {
    @extend .link-tasty;
    font-weight: 800;
  }
}

.header-content--vertical {
  min-width: percentage(math.div(7,12));
}

.date-field {
  font-style: italic;
  color: $color-text-gray;

  @include solid-breakpoint('xs') {
    font-size: 1rem;
    line-height: 1.375rem;
    margin-bottom: 14px;
  }
  
  @include solid-breakpoint('md') {
    font-size: 1.375rem;
    line-height: 1.625rem;
  }
}