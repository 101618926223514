.footer {
  position: relative;
  width: 100%;
  color: $color-text-white;
  background: $color-footer-background;
}

.footer__content {
  max-width: $content-width;
  padding: 2.5rem $space-2 $space-4 $space-2;
  margin: 0 auto;
  @include solid-breakpoint("md") {
    padding-top: $space-5;
  }
}

// offset footer on article pages on mobile because of sticky bar
.article-page ~ .footer {
  .footer__content {
    @media (max-width: $solid-md) {
      position: relative;
      bottom: 50px;
    }
  }
}

.footer__column--right {
  @include solid-breakpoint("md") {
    flex-direction: column;
    align-items: flex-end;
  }
}

.footer__logo {
  width: 154px;
  height: 78px;
  margin-bottom: $space-4;
  @include solid-breakpoint("md") {
    width: 196px;
    height: 98px;
  }
}

#ot-sdk-btn.ot-sdk-btn.footer__button {
  width: 100%;
  max-width: 340px;
  @extend .button--base;
  background-color: transparent;
  border-color: $color-footer-action;
  border-width: 2px;
  color: $color-footer-action;

  &:hover {
    background-color: transparent;
    border-color: darken($color-footer-action, 10%);
    color: darken($color-footer-action, 10%);
  }
  &:active {
    background-color: transparent;
    border-color: darken($color-footer-action, 20%);
    color: darken($color-footer-action, 20%);
  }
}

.footer__links-container {
  list-style: none;
  padding-left: 0;
  @include solid-breakpoint("md") {
    text-align: right;
    flex-grow: 1;
  }
}

.footer__newsletter {
  min-height: 190px;
}

.footer__consent--ccpa,
.footer__consent--gdpr {
  // the class is added dynamically by the `bf-utils/consent` module
  .cmp-fallback & {
    display: none;
  }
}
