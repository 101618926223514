@keyframes desktopMenuBounceIn {
  0% {
    transform: translateY(-340px);
    max-height: 360px;
  }
  1% {
    transform: translateY(-300px);
  }
  100% {
    transform: translateY(0);
  }
}

.nav__desktop-submenu {
  display: none;
  position: absolute;
  width: 100%;
  z-index: 900;
  background: $color-white-background;
  top: 76px;

  // IE11
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    padding-top: 140px;
  }
}

.nav__desktop-submenu--open {
  display: block;
  animation: desktopMenuBounceIn 0.4s;
}

.nav__desktop-submenu-content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: $content-width;
  margin: 0 auto;
  padding: $space-4 $space-4 $space-2;
  @include solid-breakpoint("lg") {
    padding-left: 237px;
  }
  @media (min-width: 72rem) {
    padding-right: 140px;
  }
}

.nav__submenu-close-button--wrapper {
  width: auto;
  margin-top: -10px;
  margin-left: $space-2;
}

.nav__submenu-close-button {
  background-color: $color-white-background;
  position: relative;
  border: 1px solid transparent;
}
