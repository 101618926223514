.sponsored-package {
  &__wrapper {
    margin: -8px 8px 66px;
    padding: 24px;
    border: 1px solid #CDEDF5;
    box-shadow: 10px 10px 0 #CEEDF5;
    border-radius: 2px;

    @include solid-breakpoint("md") {
      padding: 2rem;
    }
  }

  &__header-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    @include solid-breakpoint("md") {
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
  }
  &__title-wrapper {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 12px;

    @include solid-breakpoint("md") {
      margin-bottom: 0;
    }
  }
  &__title {
    order: 0;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1px;

    @include solid-breakpoint("md") {
      margin-left: .5rem;
      font-size: 26px;
      order: 1;
    }
  }
  &__image {
    order: 1;
    width: 20px;
    height: 20px;
    margin-left: .5rem;
    @include solid-breakpoint("md") {
      margin-left: 0;
      width: 32px;
      height: 32px;
      order: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__sponsor-disclaimer {
    display: flex;

    &__image {
      width: 32px;
      height: 32px;
      margin-left: 0;
      margin-right: .5rem;
      order: 0;

      @include solid-breakpoint("md") {
        margin-left: .5rem;
        margin-right: 0;
        order: 1;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border: 1px solid #e5e5e5;
        border-radius: 6px;
      }
    }
    &__text {
      display: flex;
      flex-direction: column;
      order: 1;

      @include solid-breakpoint("md") {
        order: 0;
      }

      span {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: left;

        @include solid-breakpoint("md") {
          text-align: right;
        }
      }
      a {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        text-decoration: underline;
        color: #222;
      }
    }
    &__name {}
  }
  &__content {
    .feed-item__scrollable-mobile {
      min-width: 240px;
    }
    @include solid-breakpoint("md") {
      margin: 0 -0.5rem 1rem;
    }
  }
  &__additional {
    font-style: normal;
    font-size: 18px;
    line-height: 22px;

    a {
      font-weight: bold;
      text-decoration: underline;
      color: #222;
    }
  }
}