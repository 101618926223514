//
// Common Modal styles
// --------------------------------------
// Used in action bar more

.modal {
  background: rgba(255,255,255,.9);
  overflow-x: hidden;
  overflow-y: auto;
}

.modal__close {
  width: 3rem;
  height: 3rem;
}

.modal--light .modal__close {
  width: 3.5rem;
  height: 3.5rem;
  margin: -2rem -2rem 0 0;
}

.modal--dark {
  background: rgba(33,41,52,.85);
}
