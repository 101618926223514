.ugc-form__button--uploader {
  cursor: pointer;
  display: block;
  text-align: center;
  height: 44px;
  width: 100%;
  padding: $space-1;
  border-radius: 4px;
  @include solid-breakpoint ("sm") {
    width: 236px;
  }
}

.ugc-form__helper-text--uploader {
  margin-top: $space-1;
}

.ugc-form__helper-test--error-adjust {
  margin-top: $space-05;
}

.ugc-form__image-uploader {
  margin-bottom: 1.1rem;
  padding-top: 0;

  .loading-donut {
    margin-top: 4rem;
  }
}

.ugc-form__image-uploader-content {
  margin-top: $space-2;
  background-color: $color-gray-background;
  margin-bottom: $space-2;
  position: relative;
  padding: $space-3;
}

.ugc-form__ul--unacceptable, .ugc-form__ul--acceptable  {
    padding-left: 0;
    list-style: none;
}

.ugc-form__ul--acceptable li:before {
  content: '✔';
  color: $color-title-green;
  margin-right: 5px;
  height: 9px;
  width: 11.7px;
}

.ugc-form__ul--unacceptable li:before {
  content: '✕';
  color: $color-title-red;
  margin-right: 5px;
  height: 9px;
  width: 9px;
}

.ugc-form__header {
  margin-bottom: $space-3;
}

.ugc-form__header-title {
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: $space-1;
}

.ugc-form__header-text {
  font-size: 14px;
  line-height: 16px;
}

.ugc-form__acceptable-image-title {
  margin-bottom: $space-1;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  color: $color-title-green;
}

.ugc-form__images-box {
  margin-bottom: $space-2;
  @include solid-breakpoint("sm") {
    display: flex;
  }
}

.ugc-form__images-box .ugc-form__acceptable-image-container:last-child {
  display: none;

  @include solid-breakpoint("sm") {
    padding-left: $space-2;
    display: block;
  }
}

.ugc-form__unacceptable-image-title {
  margin-bottom: $space-1;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  color: $color-title-red;
}

.ugc-form__acceptable-image-container {
  margin-bottom: 0.375rem;
}

.ugc-form__unacceptable-image-container {
  @include solid-breakpoint("sm") {
    padding-left: $space-3;
  }
}

.ugc-form__img--acceptable {
  object-fit: cover;
  width: 100%;
  height: 175px;

  @include solid-breakpoint("sm") {
    object-fit: cover;
    height: 166px;
    width: 296px;
    max-width: 296px;
  }
}

.ugc-form__img--unacceptable {
  object-fit: cover;
  height: 177px;
  width: 132.75px;

  @include solid-breakpoint("sm") {
    object-fit: cover;
    height: 166px;
    width: 106px;
  }
}

.ugc-form__upload-list {
  margin-top: $space-1;
  font-size: $text-5;
}

.ugc-form__emoji--acceptable {
  color: $color-text-dark;
  display: inline-block;
}

.ugc-form__emoji--unacceptable {
  color: $color-text-dark;
  display: inline-block;
}

.file-input__input {
  opacity: 0;
  height: 0;
  -webkit-appearance:none;
    position:absolute;
}

.ugc-form__img-wrapper--uploaded-image {
  max-width: 100%;
  position: relative;
  display: inline-block;
}

.ugc-form__button--remove-image {
  -webkit-appearance: none;
  width: 40px;
  height: 40px;
  background-color: white;
  border: none;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 1rem;
  right: 1rem;
  &.button--hide {
    display: none;
  }
}

.ugc-form__svg--remove-image-x.svg-x {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  &:hover {
    fill: darken($color-red-fill, 20%);
  }
}

[type=file]:focus + .ugc-form__button--uploader {
    outline: none;
    border: 1px solid $color-focus-outline;
    -webkit-box-shadow: 0 0 5px $color-focus-outline;
    box-shadow: 0 0 5px $color-focus-outline,
};
