.preparation {
  flex-basis: 0%;
}

ol.prep-steps {
  line-height: 1.4 !important;
  margin-left: $space-4;

  li {
    counter-increment: step-counter;
  }

  li::before {
    display: inline-block;
    content: counter(step-counter);
    color: $color-text-gray;
    font-weight: 800;
    margin-left: -2rem;
    width: 33px;
  }

  .Ad--prep {
    margin-left: -$space-4;
    padding: 0 0 $space-1 !important;
  }
}
