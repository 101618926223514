// search bar/lightbox in nav
.nav {
  .search-bar-form {
    position: relative;
  }
  .search-bar {
    background-color: $color-white-background;
    border: 1px solid $color-nav-background;
    border-radius: $space-4;
    display: flex;
    align-items: center;
    height: 42px;

    &:focus-within {
      border-color: $color-outline-gray;
    }
    @media (max-width: $solid-md) {
      margin-right: 0;
    }
  }
  .search-bar__search-icon {
    flex-shrink: 0;
    fill: $color-gray-fill;
    width: 20px;
    height: $space-3;
    margin: 0 10px 0px $space-2;
    outline: none;
    pointer-events: auto;
  }
  .search-bar__input {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;

    &::placeholder {
      color: $color-text-gray;
    }
  }
  .search-bar__clear-search-button {
    fill: $color-primary-action;
    display: flex;
    margin-right: $space-1;
    &:hover {
      fill: $color-primary-action-hover;
    }
    cursor: pointer;
    svg {
      width: 1.5rem;
      height: $space-3;
    }
  }
  .search-bar__clear-search-button--hide {
    display: none;
  }

  .search-bar__autosuggest {
    z-index: 200;
    width: 100%;
    background: $color-white-background;
    border: 2px solid $color-input-border;
    position: absolute;
    padding-top: 0;
    margin: 6px 36px 0px 0px;
    @include solid-breakpoint("md") {
      margin: 0px;
      margin-top: 6px;
    }
  }

  .search-bar__autosuggest > [role=list] {
    overflow-y: hidden;
  }

  .search-bar__autosuggest-link {
    padding: 8px 0 8px 18px;
    pointer-events: auto;
    color: $color-text-dark;
    display: block;
    border: 1px solid transparent;
    &, &:hover {
      color: $color-text-dark;
    }
    &:hover {
      background-color: $color-white-background;
    }
    &.highlight {
      background-color: $color-gray-background;
    }
    @include solid-breakpoint("md") {
      &:hover {
        background-color: $color-gray-background;
      }
    }
  }
}
