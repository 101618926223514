.ceros-wrapper {
    position: relative;
    width: auto;
    padding: 0 0 56.25%;
    height: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    border: 0 none;
}

.ceros-experience {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    padding: 0;
    border: 0 none;
    height: 100%;
    width: 100%
}