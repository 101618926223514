.featured-package-container {
  a {
    color: $color-text-dark;
  }

  .header-container {
    .arrow {
      display: inline;
      padding-top: 0.2rem;
    }

    @include solid-breakpoint("md") {
      &:hover {
        a {
          color: $color-primary-action;
        }

        svg {
          fill: $color-primary-action;
        }
      }
    }
  }

  .featured-package-content {
    margin: -$space-1;
  }

  .see-all-btn {
    display: block;

    h4 {
      color: $color-primary-action;
    }

    @include solid-breakpoint("md") {
      display: none !important;
    }
  }
}
