.in-app-only-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  background-color: #fff;
  color: #222;
  header {
    max-height: 50px;
    padding-top: .5rem;
    background-color: #79dcf1;;
  }
  article {
    margin: 0 1.5rem;
  }
  main {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }
  h1{
    font-size:1.75rem;
    font-weight:800;
    line-height:1.2;
    margin: 0 0 1rem;
  }
  p {
    margin: 0 0 1rem;
    font-size: 1.125rem;
    line-height: 1.2;
  }
  .nav__logo {
    display: inline-block;
    vertical-align: top;
    width: 108px;
    height: 58px;
    margin-bottom: -1rem;

  }
  @media (min-width: 52rem) {
    header {
      max-height: 58px;
      padding-top: 1rem;
    }
    .nav__logo {
      width: 144px;
      height: 69px;
      margin-bottom: -1.5rem;
    }
  }
}

