.footer-link,
#ot-sdk-btn.ot-sdk-btn.footer-link {
  display: inline-block;
  color: $color-footer-action;
  font-weight: $font-weight-extra-bold;
  text-decoration: underline;
  font-size: $text-3-size;
  line-height: 1.7;
  &:hover {
    color: darken($color-footer-action, 10%);
    background-color: transparent;
  }
  @include button-reset();
}
