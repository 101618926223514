.article__primary-content .subbuzzes {
  .subbuzz__description {
    a {
      font-weight: $font-weight-extra-bold;
    }
  }
  // prevent wide images from going outside margins
  .subbuzz-bfp--illustration_uploader {
    overflow-x: hidden;
  }
  // BFP related links
  .subbuzz-bfp--related_links {
    .bfp-related-links__link {
      @extend .link-tasty;
      color: $color-primary-action;
      text-decoration: underline;
      font-size: $text-3-size;
      font-weight: $font-weight-extra-bold;
      margin-bottom: 0;
      &:hover {
        color: $color-primary-action-hover;
      }
      @include solid-breakpoint("md") {
        font-size: $text-2-size;
      }
    }
    .bfp-related-links__title {
      font-size: $text-2-size;
      text-transform: none;
    }
  }
}

