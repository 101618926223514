.tastyvalues-page {
    &__container {
      max-width: 75rem;
      margin: 0 auto;
      padding: 0 1rem;
  
      @include solid-breakpoint("md") {
        margin-top: $space-2;
        margin-bottom: 250px;
      }
    };
    &__title {
      font-weight: $font-weight-extra-bold;
      font-size: 2rem;
      line-height: 1.2;
      margin: $space-3 0 $space-3 0;
  
      @include solid-breakpoint("sm") {
        font-size: 3rem;
        line-height: 1.1;
        margin: 40px 0 40px 0;
        max-width: 80%;
      }
  
      @include solid-breakpoint("lg") {
        font-size: 3.5rem;
        line-height: 1.1;
      }
    };
    &__h2 {
        font-weight: $font-weight-extra-bold;
        font-size: $text-3-size;
        margin: $space-1 0 $space-1 0;

        @include solid-breakpoint("sm") {
            font-size: $text-2-size;
          }
      
          @include solid-breakpoint("lg") {
            font-size: $text-1-size;
          }
    };
    &__content {
      font-size: $text-4-size;
      @include solid-breakpoint("sm") {
        font-size: $text-3-size;
      }
      @include solid-breakpoint("md") {
        line-height: 1.7;
      }
      p {
        margin-bottom: $space-3;
      }
    }
    &__link {
      color: $color-primary-action;
      &:hover {
        color: $color-primary-action-hover;
      }
    }
  }
