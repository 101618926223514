$nav-height: 76px;
$tasty-logo-width-lg: 195px;
$tasty-logo-width-md: 110px;

// entire nav bar component
.nav {
  top: 0;
  z-index: 998;

  @include solid-breakpoint("md") {
    position: sticky;
  }
}

.nav__contents {
  background: $color-nav-background;
  height: $nav-height;
  max-height: $nav-height;
  width: 100%;

  button:not(.button--tasty),
  a:not(.button--tasty) {
    &.shop__category-item-link--cookware {
      border: 1px solid transparent;
    }

    &:focus-visible {
      border: 1px solid $color-outline-gray;
      outline-style: none;

      &.shop__category-item-link--cookware {
        border: 1px solid transparent;
      }
    }
  }

  .nav__primary-menu-button {
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 3px solid transparent;
    color: $color-text-dark;
    font-size: $text-3-size;
    line-height: 1.25;
    padding: 2px;
    font-weight: $font-weight-extra-bold;
    border: 1px solid transparent;

    &:hover,
    &:active,
    &.nav__primary-menu-button--active {
      .nav__primary-menu-button-text::after {
        content: '';
        width: calc(100% - 24px);
        height: 3px;
        background: $color-text-dark;
        display: block;
        margin-top: 2px;
        position: absolute;
      }
    }

    @include solid-breakpoint("lg") {
      font-size: $text-2-size;
    }

    //Keyboard user displays focus ring for additional focus
    &:focus-visible {
      border: 1px solid $color-outline-gray;
    }
  }

  .nav__primary-menu {
    @include solid-breakpoint("md") {
      background: $color-nav-background;
      height: $nav-height;
      position: absolute;
      width: 100%;
      z-index: 999;
    }
  }

  .nav__primary-menu-bar {
    max-width: $content-width;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $nav-height;
    margin: 0 auto;
    padding: 0 $space-2;
  }

  .nav__primary-menu-item--last {
    flex-grow: 1;
  }

  .nav__primary-menu-item {
    display: none; // primary menu top links are hidden on mobile

    @include solid-breakpoint("md") {
      display: flex;
      align-items: center;
      padding: $space-2 $space-4 $space-2 $space-2;
    }

    @include solid-breakpoint("lg") {
      padding-right: 46px;
    }
  }

  .nav__primary-menu-item-icon {
    width: 10px;
    height: 10px;
    margin-left: 10px;
  }

  .nav__primary-menu-search--desktop {
    display: none;
    flex-shrink: 0;

    @include solid-breakpoint("md") {
      width: 25%;
      min-width: 180px;
      display: block;
      pointer-events: auto; // may not be needed?
    }
  }

  .nav__primary-menu-search {
    position: relative;

    input:focus {
      outline: none;
    }
  }

  .nav__primary-menu-mycart {
    margin-left: auto;
    margin-right: 16px;
    position: relative;

    @include solid-breakpoint("md") {
      margin-left: 36px;
      margin-right: 0;

      .nav__primary-menu-mycart-link {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .nav__primary-menu-mycart-items {
      position: absolute;
      background-color: $color-tasty-pink-5;
      color: $color-white;
      font-weight: $font-weight-bold;
      font-size: 12px;
      line-height: 12px;
      width: 17px;
      height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      top: -6px;
      left: 18px;
      transition: transform .2s ease-in-out;

      animation: scaleUp;
      animation-duration: .35s;
      animation-timing-function: linear;
    }

    &:hover {
      .nav__primary-menu-mycart-items {
        transform: scale(1.3);
      }
    }

    .nav__primary-menu-mycart-tooltip {
      border-radius: 4px;
      background: white;
      padding: 1rem;
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 247px;
      height: 160px;
      right: -2rem;
      top: 3rem;
      line-height: 18px;
      box-shadow: 7px 4px 28px #dadada;
      z-index: 999;

      button {
        margin-top: 0.7rem;
        padding: 5px 15px;
        width: fit-content;
        font-weight: 800;
        font-size: 14px;
        line-height: 18px;
      }

      p {
        font-weight: 800;
        line-height: 22px;
      }
    }

    .nav__primary-menu-mycart-tooltip:before {
      bottom: 100%;
      left: 84%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: white;
      border-width: 10px;
      margin-left: -11px;
    }


    @keyframes scaleUp {
      0% {
        transform: scale(0);
        color: $color-tasty-pink-5;
      }

      50% {
        transform: scale(1);
      }

      75% {
        transform: scale(1.5);
      }

      100% {
        transform: scale(1);
        color: $color-white;
      }
    }
  }

  .nav__submenu-button {
    position: relative;
    margin-top: -6px;

    @include solid-breakpoint("md") {
      display: none;
    }
  }

  .nav__submenu-button-icon {
    margin: 4px;
  }

  .nav__submenu-button--close {
    margin-top: -62px;
    left: 88%;
    width: 2.5rem;
    height: 2.5rem;

    &:focus {
      outline: none;
    }

    @include solid-breakpoint("sm") {
      left: 92%;
    }

    .nav__submenu-button-icon {
      width: 2rem;
      height: 2rem;
    }
  }

  .nav__submenu-button--open {

    .nav__submenu-button-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  // tasty logo
  .nav__logo-container {
    position: relative;
    flex-shrink: 0;
    width: 98px;
    height: 49px;
    margin: 14px 17px 18px 2px;

    @include solid-breakpoint("md") {
      width: $tasty-logo-width-md;
      height: 56px;
      margin: 10px 17px 10px 0;
    }

    @include solid-breakpoint("lg") {
      width: $tasty-logo-width-lg;
      height: 98px;
      margin: 72px 10px 18px 0;
    }
  }

  .nav__logo {
    position: relative;
    display: flex;
    z-index: 900;
    height: 100%;
    border: 1px solid transparent;

    &:active {
      border: none;
      box-shadow: none;
      outline-style: none;
    }

    svg {
      // Customized grow from hover.css library - expanding of tasty logo on hover
      -ms-transform: perspective(1px) translateZ(0);
      -moz-transform: perspective(1px) translateZ(0);
      -webkit-transform: perspective(1px) translateZ(0);
      -o-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px transparent;
      transition: transform .2s cubic-bezier(.02, .47, .54, .94);

      &:hover,
      &:focus,
      &:active {
        -ms-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }
}

.nav__recipes-submit-container {
  background: $color-gray-background;
}

.nav__recipes-submit-content {
  font-size: $text-4-size;
  line-height: $text-4-line-height;
  padding-bottom: $space-4;
  width: 100%;

  @include solid-breakpoint("md") {
    max-width: $content-width;
    margin: 0 auto;
    padding-left: $space-4;
    padding-top: $space-4;
    padding-bottom: $space-4;
  }

  @include solid-breakpoint("lg") {
    padding-left: 240px;
  }
}

.nav__recipes-submit-heading {
  font-size: $text-4-size;
  line-height: $text-4-line-height;
  font-weight: $font-weight-extra-bold;
  padding-bottom: $space-05;
}

.nav__recipes-submit-link {
  display: inline-block;
  margin-left: $space-1;
  padding: 0px 2px;
  font-weight: $font-weight-extra-bold;
  border: 1px solid transparent;

  @include solid-breakpoint("md") {
    padding-bottom: 1px;
  }
}

.nav__recipes-submit-button.button--tasty {
  display: block;
  margin-top: $space-2;
  font-weight: $font-weight-extra-bold;
}

.nav__desktop-submenu {
  display: none;

  @include solid-breakpoint("md") {
    display: block;
  }
}

.nav__desktop-submenus-background {
  background: rgba(33, 41, 52, .45);
  height: 100vh;
  width: 100%;
  z-index: 800;
  position: fixed;
  left: 0;
  top: 0;
}

//ABFlipper styling
.abFlipper {
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  background: #fff;
  border: 1px solid #ccc;
  padding: 1rem;
  font-weight: 800;

  // title
  &>div:first-of-type {
    padding-right: 80px;
    flex: 0 0 auto;
    position: relative;
  }

  button {
    -webkit-appearance: none;
    padding: 0;
    border: 0;
    background: none;
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;

    // visually hidden
    span {
      position: absolute;
      width: 0;
      height: 0;
      overflow: hidden;
      border: 0;
      text-indent: -9999em;
      text-align: left;
      display: block;
    }
  }

  button[title="Hide All"] {
    transform: translateY(-50%) rotate(180deg);
  }

  button[title="Show All"] {
    transform: translateY(-50%);
  }

  // actions
  ul+div {
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-start;
  }

  $action-color: $color-primary-action;

  a {
    color: $action-color;
    margin-right: 1rem;
    display: block;
    padding: 0.25rem 0.75rem;
    border-radius: 4px;
    border: 1px solid $action-color;
    transition: all 200ms ease;
    text-decoration: none;

    &:hover,
    &:focus {
      color: #fff;
      background: $action-color;
    }

    &:first-of-type {
      background: $action-color;
      color: #fff;

      &:hover,
      &:focus {
        border-color: darken($action-color, 20%);
        background: darken($action-color, 20%);
      }
    }
  }

  a:first-of-type {
    background-color: $color-primary-action;
    border: 1px solid transparent;
  }

  ul {
    border: 1px solid #ccc;
    margin: 1rem 0;
    padding: 0;
    list-style: none;
    flex: 1 1 auto;
    overflow: auto;
  }

  li {
    display: flex;
    padding: 0.5rem;

    &:nth-child(even) {
      background: #f5f5f5;
    }

    label {
      font-weight: 500;
      flex: 1 1 auto;
      display: block;
      margin-right: 1rem;
      text-overflow: ellipsis;
    }

    select {
      width: 10rem;
      flex: 0 0 auto;
      font-size: 1rem;
      -webkit-appearance: menulist-button;
    }
  }

  svg {
    height: 80%;
    width: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

// hide content below when open for full height menus
@media (max-width: $solid-md) {

  .nav-mobile-menu__open~#content,
  .nav-mobile-menu__open~section,
  .nav-mobile-menu__open~footer {
    display: none;
  }
  .nav-mobile-menu__open .nav__primary-menu-mycart {
    margin-right: 40px;
  }
}

@media (min-width: 500px) and (max-width: $solid-lg) {
  .nav-mobile-menu__open .nav__primary-menu-mycart {
    margin-right: 60px;
  }
}

.nav__skip-to-content {
  position: fixed;
  top: 0;
  left: 0;
  background: $color-white-background;
  padding: $space-1;
  text-indent: -9999em;
  z-index: -1;

  &:focus {
    z-index: 999;
    text-indent: 0;
  }
}