.shows__heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: $space-2;
  align-items: center;
}

.shows__see-more.link-tasty {
  text-decoration: underline;
}

.shows__additional {
  @include solid-breakpoint("md") {
    .shows__show:first-child {
      margin-right: $space-1;
    }
    .shows__show:last-child {
      margin-left: $space-1;
    }
  }
}

.shows__video .video {
  border-radius: 4px;
  overflow: hidden;
}

.shows__video-title {
  margin-top: $space-1;
  font-size: $text-4-size;
  line-height: 1.2;

  @include solid-breakpoint("sm") {
    font-size: $text-2-size;
  }

  @include solid-breakpoint("md") {
    font-size: $text-2-size;
    margin-top: 0.75rem;
    &.shows__video-title--first {
      font-size: $text-1-size;
    }
  }
}

.shows__video-link {
  display: inline-block;
  font-size: $text-5-size;
  text-decoration: underline;
  margin: $space-05 0 $space-3 0;

  @include solid-breakpoint("sm") {
    font-size: $text-3-size;
  }

  @include solid-breakpoint("md") {
    font-size: $text-3-size;
    margin-bottom: 0;
    &.shows__video-link--first {
      font-size: $text-2-size;
    }
  }
}

.shows__show {
  .video-js .vjs-tech, .video-js-player--loading {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
  }
  .video-js-player--loading {
    left: -1px;
    top: -1px;
  }
}
.shows__show .video-js-player.video-js-player--big-button .vjs-big-play-button{
  height: 46px;
  width: 46px;
  top: calc(50% - 23px);
  left: calc(50% - 23px);
  // On desktop - default to medium big play button for desktop for all shows
  @include solid-breakpoint("md") {
    height: 60px;
    width: 60px;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    font-size: 2.5rem;
  }
}
.shows__see-more.button--tasty {
  @include solid-breakpoint("md") {
    display: none;
  }
}
// override first big play button on desktop to be largest size
.shows__main .shows__show .video-js-player.video-js-player--big-button .vjs-big-play-button{
  @include solid-breakpoint("md") {
    height: 84px;
    width: 84px;
    top: calc(50% - 42px);
    left: calc(50% - 42px);
    font-size: 3.5rem;
  }
}

.shows .shows__main .shows__show .shows__video .cnx-main-container  {
  max-width: initial !important
}