.recipe-tips {
  margin-top: $space-4;
  @include solid-breakpoint("md") {
    margin-top: $space-5;
  }
}

.recipe-tips_container {
  display: flex;
  margin-top: $space-2;

  @media (max-width: $solid-md) {
    margin-left: -1rem;
    margin-right: -1rem;
    flex-wrap: nowrap;
    overflow: auto;
    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;
  }
}

.recipe-tips_container::-webkit-scrollbar {
  display: none;
}

.recipe-tips_card-wrapper {
  flex: 1;

  @media (max-width: $solid-md) {
    padding-right: $space-2;

    &:first-child {
      padding-left: $space-2;
    }
  }

  @include solid-breakpoint("md") {
    padding: 0;
    &:nth-child(2) {
      padding: 0 $space-2;
    }
  }
}

.recipe-tips_card {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  background-color: $color-gray-background;
  border-radius: 4px;
  padding: $space-2 $space-2 $space-3 $space-3;

  @media (max-width: $solid-md) {
    min-width: 287px;
  }
}

.recipe-tips_text-wrapper {
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-right: $space-2;
}

.recipe-tips_author-image {
  img {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    object-fit: cover;

    @include solid-breakpoint("md") {
      height: 35px;
      width: 35px;
    }
  }
}

.recipe-tips_recipe-image {
  flex: 1;

  img {
    height: 80px;
    width: 80px;
    border-radius: 4px;
    object-fit: cover;

    @include solid-breakpoint("md") {
      height: 106px;
      width: 106px;
    }
  }
}
