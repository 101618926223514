.stories {
  .video {
    border-radius: 4px;
    overflow: hidden;
  }
  .video-js-player {
    margin-top: -2px;
    margin-bottom: -2px;
  }
  .video-js-player.video-js-player--big-button .vjs-big-play-button {
    height: 84px;
    width: 84px;
    top: calc(50% - 42px);
    left: calc(50% - 42px);
    font-size: 3.65rem;
  }
}

.stories__follow.button--tasty {
  display: block;
  @include solid-breakpoint("md") {
    display: none;
  }
}

.stories__heading {
  margin-bottom: 0.75rem;
}

.stories__link--desktop {
  text-decoration: underline;
}
