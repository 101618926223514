@use "sass:math";
.feed-page-description {
  font-size: $text-4-size;
  line-height: $text-4-line-height;
  padding-top: $space-05;
  @include solid-breakpoint("sm") {
    width: percentage(math.div(5,6));
  }
  @include solid-breakpoint("md") {
    font-size: $text-2-size;
    line-height: $text-2-line-height;
  }
  @include solid-breakpoint("lg") {
    width: percentage(math.div(2,3));
  }
  &.feed-page-description--ugc-submission {
    @include solid-breakpoint("sm") {
      width: percentage(math.div(5,6));
    }
  }
}

.feed-page-description__text--ugc-submission {
  font-size: $text-4-size;
  line-height: 1.5;
  display: block;
  &:nth-child(2) {
    margin: $space-2 0 $space-3 0;
    @include solid-breakpoint("md") {
      margin-top: 0;
    }
  }
}

.feed-page-description__text--contest-submission {
  font-size: $text-4-size;
  margin-bottom: $space-3;
  line-height: 1.5;
}
