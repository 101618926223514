
.social-buttons__list {
  display: flex;
  list-style: none;
  padding-left: 0;
}

.social-buttons__heading {
  font-weight: $font-weight-extra-bold;
  font-size: $text-3-size;
  margin-bottom: $space-1;
}

.social-button {
  display: inline-block;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 2px solid $color-footer-action;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 20px;
    width: 20px;
    fill: $color-footer-action;
  }
  margin-right: $space-2;
  &:hover {
    border-color: darken($color-footer-action, 10%);
    svg {
      fill: darken($color-footer-action, 10%);
    }
  }
}
