.ad-adapted {
  height: 250px;
  width: 300px;
  margin: 0 auto;

  &.group_a {
    height: 200px;
    margin: 0;
    width: inherit;
  }
}

.ad-adapted-disclosure {
  letter-spacing: 1px;
  color: #757575;
  font-size: .75rem;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 0.5rem;
  text-align: center;
  text-transform: uppercase;
}
