.sidebar__guides--lg {
    display: none;
    @include solid-breakpoint("lg") {
      display: flex;
      align-items: flex-start;
      height: 100%;
    }
  }

.sticky-wrapper__sidebar--guides {
    .feed__heading {
      margin-left: 0;
    }
    @include solid-breakpoint("lg") {
        top: 72px;
        min-height: 200px;
        position: sticky;
        padding-left: $space-1;
        padding-right: $space-1;
    }
}
