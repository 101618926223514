.mobile-submenu {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: $color-white-background;

  @include solid-breakpoint("md") {
    display: none;
  }
}

.mobile-submenu-container {
  &:focus {
    outline: none;
    border: none;
  }
  .mobile-submenu__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding: $space-3;
    &:hover {
      cursor: pointer;
    }
    border: 1px solid transparent;
    &:focus, &:focus-within {
      outline-style: none;
      border-color: $color-outline-gray;
    }
  }
}

.mobile-submenu__search {
  background-color: $color-nav-background;
  padding: 16px 20px 20px;
}

.mobile-submenu__entry-heading {
  display: flex;
  align-items: center;
}

.mobile-submenu__item-container {
  border-bottom: 2px solid $color-gray-1;
}

.mobile-submenu__item-title {
  font-size: $text-2-size;
  line-height: $text-2-line-height;
  font-weight: $font-weight-extra-bold;
}

.mobile-submenu__item-icon {
  width: 0.75rem;
  height: 0.75rem;
}

.mobile-submenu__item-icon--toggled {
  transform: rotate(180deg);
}

.mobile-submenu__item-contents {
  display: flex;
  flex-wrap: wrap;
  padding: $space-2 $space-3 0px;
}

.mobile-submenu__item-contents--tricks {
  .nav__submenu-category-wrapper:nth-child(odd) {
    width: 60%;
  }
  .nav__submenu-category-wrapper:nth-child(even) {
    width: 40%;
  }
}
